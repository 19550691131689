const baseUrl = window.location.hostname === 'localhost' ? 'http://localhost' : '';

export default class providerFE {
  registrazione(dataBody) {
    return fetch(baseUrl + "/lumi/api/registrazione.php", {
      method: "POST",
      body: dataBody,
    })
      .then((response) => response.text())
      .then((data) => {
        return data;
      })
      .catch({});
  }

  insertCandidatura(dataBody) {
    return fetch(baseUrl + "/lumi/api/candidatura.php", {
      method: "POST",
      body: dataBody,
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch({});
  }

  sendEmail(email, nome) {
    let formData = new FormData();
    formData.append("email", email);
    formData.append("nome", nome);
    return fetch(baseUrl + "/lumi/api/emailConfirmation.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        return data;
      })
      .catch({});
  }

  checkToken(token) {
    let formData = new FormData();
    formData.append("token", token);
    return fetch(baseUrl + "/lumi/api/tokenConfirmation.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        return data;
      })
      .catch({});
  }

  login(dataBody) {
    return fetch(baseUrl + "/lumi/api/login.php", {
      method: "POST",
      body: dataBody,
    })
      .then((response) => response.text())
      .then((data) => {
        return data;
      })
      .catch({});
  }

  updateToken(token) {
    return fetch(baseUrl + "/lumi/api/tokenUpdate.php", {
      method: "POST",
      body: token,
    })
      .then((response) => response.text())
      .then((data) => {
        return data;
      })
      .catch({});
  }

  insertJobPosition(dataBody) {
    return fetch(baseUrl + "/lumi/api/insertJobPosition.php", {
      method: "POST",
      body: dataBody,
    })
      .then((response) => response.text())
      .then((data) => {
        return data;
      })
      .catch({});
  }

  getCards(dataBody) {
    return fetch(baseUrl + "/lumi/api/getCards.php", {
      method: "POST",
      body: dataBody,
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch({});
  }

  updateCandidatura(dataBody) {
    return fetch(baseUrl + "/lumi/api/updateCandidatura.php", {
      method: "POST",
      body: dataBody,
    })
      .then((response) => response.text())
      .then((data) => {
        return data;
      })
      .catch({});
  }

  getAllinfo(dataBody) {
    return fetch(baseUrl + "/lumi/api/job_position_info.php", {
      method: "POST",
      body: dataBody,
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch({});
  }

  getCompetenze() {
    return fetch(baseUrl + "/lumi/api/competenze.php", {
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch({});
  }

  sendInfoEmail(dataBody) {
    return fetch(baseUrl + "/lumi/api/email.php", {
      method: "POST",
      body: dataBody,
    })
      .then((response) => response.text())
      .then((data) => {
        return data;
      })
      .catch({});
  }
}
