import React, { Component } from 'react'
import './styles/confirm.css';
import Provider from './providerFE.jsx';
import * as CompletedLottie from '../assets/complete.json';
import * as AlertLottie from '../assets/alert.json';
import AOS from 'aos';
import Lottie from 'react-lottie';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

export default class confirm extends Component {

    constructor(props){
        super(props);
        this.state = {isStopped: false, isPaused: false,all:{
            responseStatus:"",
            log:""
        }};
       
    }

    componentWillMount(){
        const { token }  = this.props.match.params;
        this.checkToken(token);
    }
    

    componentDidMount(){  
        AOS.init();
        this.setState({isStopped: false});
    }

    checkToken(token){
        let provider = new Provider();
           provider.checkToken(token).then(data =>{

            this.setState({all: JSON.parse(data)});
            
        });
    }

    render() {

        const completedOptions = {
            loop: false,
            autoplay: true,  
            animationData: CompletedLottie.default,
            rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
            }
        };
    
        const alertOptions = {
          loop: false,
          autoplay: true,  
          animationData: AlertLottie.default,
          rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
          }
        };

        switch (this.state.all.log) {
            case "Account Confirmed":
                return (
                    <div className="pendingBody"> 
                        <div style={{padding:"0px"}} className="pendingEmail">
                        <Lottie options={completedOptions}
                        speed={0.9}
                        isStopped={this.state.isStopped}
                        isPaused={this.state.isPaused}/>
                        </div>
                        <h1 style={{marginTop:"60px"}} data-aos="fade-right" data-aos-duration="800" data-aos-offset="-2000" data-aos-easing="ease-out-quart" data-aos-delay="1000" >Registrazione Completata</h1>   
                        <div data-aos="fade-up" data-aos-duration="800" data-aos-offset="-2000" data-aos-easing="ease-out-quart" data-aos-delay="1500">
                            <p style={{marginTop: "70px"}}>Clicca sul pulsante per iniziare a navigare sulla piattaforma</p> 
                        </div>
                        <div className="pendingButtonsContainer">
                        <Link to={{pathname:"/home",state:{
                            data:{nome:this.state.all.nome,
                                  token:this.props.match.params.token,
                                  cognome:this.state.all.cognome,
                                  email:this.state.all.email,
                                  nome_azienda:this.state.all.nome_azienda,
                                  id:this.state.all.id
                                  }}}} ><button style={{background:"linear-gradient(to bottom left, #69f3da, lightgreen)",marginRight:"0px"}} data-aos="zoom-in-up" data-aos-duration="500" data-aos-offset="-2000" data-aos-delay="2000" className="pendingButton">Torna alla Home</button></Link>
                        </div>
                    </div>
                );
            case "Account NOT Confirmed":
                    return (
                        <div className="pendingBody"> 
                            <div style={{padding:"0px"}} className="pendingEmail">
                            <Lottie options={alertOptions}
                            speed={0.8}
                            isStopped={this.state.isStopped}
                            isPaused={this.state.isPaused}/>
                            </div>
                            <h1 data-aos="fade-right" data-aos-duration="800" data-aos-offset="-2000" data-aos-easing="ease-out-quart" data-aos-delay="800" >Si è verificato un errore</h1>   
                            <div data-aos="fade-up" data-aos-duration="800" data-aos-offset="-2000" data-aos-easing="ease-out-quart" data-aos-delay="1100">
                                <p>Si è verificato un errore durante la registrazione.</p>
                                <p>Clicca sul pulsante Torna Indietro per tornare alla fase di registrazione o contatta il supporto tecnico se il problema persiste</p> 
                            </div>
                            <div className="pendingButtonsContainer">
                            <Link to="/registrazione"><button data-aos="zoom-in-up" data-aos-duration="500" data-aos-offset="-2000" data-aos-delay="1400" className="pendingButton error">Torna indietro</button></Link>
                                <button data-aos="zoom-in-up" data-aos-duration="500" data-aos-offset="-2000" data-aos-delay="1700" className="pendingButtonSupport">Supporto Tecnico</button>
                            </div>
                        </div>
                    );
            default:
                return null;
        }
    }
}
