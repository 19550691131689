import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Typography,
  Upload,
} from "antd";
/* import moment from "moment"; */
import React, { useEffect, useState } from "react";
import { apiConfig } from "../config/apiConfig";
import Provider from "./providerFE.jsx";

const { TextArea } = Input;

const CandidateForm = () => {
  const [form] = Form.useForm();
  const { Title } = Typography;

  const onFinish = (values) => {
    console.log("Form values:", values);
    handleSubmit(values);
  };

  const [files, setFiles] = useState([]);

  const handleFileChange = (info) => {
    setFiles(info.fileList);
  };

  const handleSubmit = async (data) => {
    const updatedData = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== undefined)
    );
    updatedData.inserted_by = sessionStorage.getItem("id");

    const formData = new FormData();

    Object.keys(updatedData).forEach((key) => {
      formData.append(key, updatedData[key]);
    });

    files.forEach((file) => {
      formData.append("files", file.originFileObj);
    });

    try {
      const response = await fetch(apiConfig.candidates, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const result = await response.text();
        console.log("Success:", result);
        window.location.reload();
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const [competenzeOptions, setCompetenzeOptions] = useState([]); // State for competenze options
  useEffect(() => {
    const fetchCompetenze = async () => {
      const provider = new Provider();
      try {
        const data = await provider.getCompetenze();
        const options = data.map((elem) => ({ value: elem }));
        setCompetenzeOptions(options);
      } catch (error) {
        console.error("Error fetching competenze:", error);
      }
    };
    fetchCompetenze();
  }, []);

  return (
    <Form form={form} id="candidateForm" layout="vertical" onFinish={onFinish}>
      <Title level={4}>Informazioni personali</Title>
      <Form.Item name="nome" label="Nome" required={true}>
        <Input />
      </Form.Item>
      <Form.Item name="cognome" label="Cognome" required={true}>
        <Input />
      </Form.Item>
      <Form.Item name="data_di_nascita" label="Data di Nascita">
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="luogo_di_nascita" label="Luogo di Nascita">
        <Input />
      </Form.Item>
      <Form.Item name="sesso" label="Sesso" required={true}>
        <Input />
      </Form.Item>
      <Form.Item name="nazionalita" label="Nazionalità" required={true}>
        <Input />
      </Form.Item>

      <Title level={4}>Contatti</Title>
      <Form.Item name="email" label="Email">
        <Input type="email" />
      </Form.Item>
      <Form.Item name="telefono" label="Telefono">
        <Input />
      </Form.Item>
      <Form.Item name="indirizzo" label="Indirizzo">
        <Input />
      </Form.Item>
      <Form.Item name="citta" label="Città">
        <Input />
      </Form.Item>
      <Form.Item name="provincia" label="Provincia">
        <Input />
      </Form.Item>
      <Form.Item name="cap" label="CAP">
        <Input />
      </Form.Item>
      <Form.Item name="linkedin" label="LinkedIn">
        <Input />
      </Form.Item>
      <Form.Item name="skype" label="Skype">
        <Input />
      </Form.Item>

      <Title level={4}>Informazioni professionali</Title>
      <Form.Item name="settore" label="Settore" required={true}>
        <Input />
      </Form.Item>
      <Form.Item
        name="figura_professionale"
        label="Figura Professionale"
        required={true}
      >
        <Input />
      </Form.Item>
      <Form.Item name="seniority" label="Seniority" required={true}>
        <Select>
          <Select.Option value="Junior">Junior</Select.Option>
          <Select.Option value="Middle">Middle</Select.Option>
          <Select.Option value="Senior">Senior</Select.Option>
          <Select.Option value="Neo-laureato">Neo-laureato</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="anni_esperienza"
        label="Anni di Esperienza"
        required={true}
      >
        <Input type="number" />
      </Form.Item>

      <Title level={4}>Competenze ed esperienze</Title>
      {[1, 2, 3, 4].map((num) => (
        <React.Fragment key={num}>
          <Form.Item name={`competenza_${num}`} label={`Competenza ${num}`}>
            <Select>
              {competenzeOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={`esperienza_${num}`}
            label={`Esperienza ${num} (anni)`}
          >
            <Select>
              <Select.Option value="0.5 Anni">0.5 Anni</Select.Option>
              <Select.Option value="1 Anno">1 Anno</Select.Option>
              <Select.Option value="2 Anni">2 Anni</Select.Option>
              <Select.Option value="3 Anni">3 Anni</Select.Option>
              <Select.Option value="4 Anni">4 Anni</Select.Option>
              <Select.Option value="5 Anni">5 Anni</Select.Option>
              <Select.Option value="6 Anni">6 Anni</Select.Option>
              <Select.Option value="7 Anni">7 Anni</Select.Option>
              <Select.Option value="8 Anni">8 Anni</Select.Option>
              <Select.Option value="9 Anni">9 Anni</Select.Option>
              <Select.Option value="10 Anni">10 Anni</Select.Option>
              <Select.Option value="Più di 10 Anni">
                Più di 10 Anni
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={`descrizione_esperienza_${num}`}
            label={`Descrizione Esperienza ${num}`}
          >
            <TextArea rows={4} />
          </Form.Item>
        </React.Fragment>
      ))}

      <Title level={4}>Disponibilità</Title>
      <Form.Item name="disponibile_dal" label="Disponibile Dal">
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="disponibile_al" label="Disponibile Al">
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>

      <Title level={4}>Documentazione</Title>
      <Form.Item name="files" label="Files">
        <Upload onChange={handleFileChange} multiple beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Select Files</Button>
        </Upload>
      </Form.Item>

      <Title level={4}>Dettagli contrattuali</Title>
      <Form.Item name="tipologia_contratto" label="Tipologia Contratto">
        <Select>
          <Select.Option value="Tempo indeterminato">
            Tempo indeterminato
          </Select.Option>
          <Select.Option value="Apprendistato">Apprendistato</Select.Option>
          <Select.Option value="Partita Iva">Partita Iva</Select.Option>
          <Select.Option value="Fornitore">Fornitore</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="data_contratto" label="Data Contratto">
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="ral" label="RAL">
        <Input type="number" />
      </Form.Item>
      <Form.Item name="rate" label="Rate">
        <Input type="number" />
      </Form.Item>

      <Title level={4}>Stato e valutazione</Title>
      <Form.Item name="urgenza" label="Urgenza">
        <Select>
          <Select.Option value="Bassa">Bassa</Select.Option>
          <Select.Option value="Media">Media</Select.Option>
          <Select.Option value="Alta">Alta</Select.Option>
          <Select.Option value="Massima">Massima</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="status" label="Status">
        <Select>
          <Select.Option value="Dipendente">Dipendente</Select.Option>
          <Select.Option value="Esterno">Esterno</Select.Option>
          <Select.Option value="Freelance">Freelance</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="stato_candidatura" label="Stato Candidatura">
        <Select>
          <Select.Option value="Call telefonica">Call telefonica</Select.Option>
          <Select.Option value="Colloquio conoscitivo">
            Colloquio conoscitivo
          </Select.Option>
          <Select.Option value="Colloquio tecnico">
            Colloquio tecnico
          </Select.Option>
          <Select.Option value="Colloquio tecnico cliente">
            Colloquio tecnico cliente
          </Select.Option>
          <Select.Option value="Allocato">Allocato</Select.Option>
          <Select.Option value="KO">KO</Select.Option>
          <Select.Option value="Disponibile">Disponibile</Select.Option>
          <Select.Option value="Lettera d'intenti">
            Lettera d'intenti
          </Select.Option>
        </Select>
      </Form.Item>

      <Title level={4}>Informazioni aggiuntive</Title>
      <Form.Item name="profilo_candidato" label="Profilo Candidato">
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item name="referente_hr" label="Referente HR">
        <Input />
      </Form.Item>
      <Form.Item name="data_contatto" label="Data Contatto">
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Salva
        </Button>
      </Form.Item>

      {/* <Form.Item>
        <Button
          onClick={() => {
            const today = moment();
            form.setFieldsValue({
              nome: "Mario",
              cognome: "Rossi",
              data_di_nascita: moment("1990-01-01"),
              luogo_di_nascita: "Milano",
              sesso: "M",
              nazionalita: "Italiana",

              email: "mario.rossi@example.com",
              telefono: "3331234567",
              indirizzo: "Via Roma 123",
              citta: "Milano",
              provincia: "MI",
              cap: "20100",

              settore: "Information Technology",
              figura_professionale: "Full Stack Developer",
              seniority: "Middle",
              anni_esperienza: "5",

              disponibile_dal: today,
              disponibile_al: moment(today).add(3, "months"),
            });
          }}
        >
          Fill Test Data
        </Button>
      </Form.Item> */}
    </Form>
  );
};

export default CandidateForm;
