import React, { Component } from 'react';
import Provider from './providerFE.jsx';
import './styles/login.css';

export default class login extends Component {
    constructor(props){
        super(props)
        this.state = {};
    }

    login = () =>{

        let formCompleted = this.check();
        if(!formCompleted) return;

        let formData = new FormData();
        formData.append("data",JSON.stringify(this.state).toString());   
        let provider = new Provider();
        
        provider.login(formData).then(data => {
            
           
            this.setState({data: JSON.parse(data)});
            switch (this.state.data.log) {
                case "ok":
                    this.props.history.push({
                        pathname: '/home',
                        state: { data : this.state.data }
                    });
                    break;
                default:
                    this.loginError();
                    break;
            }
            
        });

    }
    loginError(){
        let error = document.getElementById('inputErrorLogin');
        switch (this.state.data.log) {
            case "bad":
                error.innerHTML = "Si è verificato un errore. <br/> La password inserita non è corretta o l'account non esiste.";
                error.style.display = "block";
                break;
        
            default:
                error.innerHTML = "Si è verificato un errore di connessione. <br/> Ritenta nuovamente e se il problema persiste contatta il supporto Tecnico.";
                error.style.display = "block";
                break;
        }
    }
    
    changeColor(e) {
        e.target.style.borderBottom = "1px solid #009dd6";
    }

    updateValue =  (element,event) =>{
       
        this.setState({
           [element] : event.target.value
        });
        
    }

    check() {
        let form = document.getElementById('formLogin');
        let error = document.getElementById('inputErrorLogin');
        let email = document.getElementById('inputLoginEmail');
        let children = form.children;
        let fine = true;
        error.style.display = "none";

        Array.prototype.forEach.call(children, child => {
            if(child.value === "" || child.value == null){
                child.style.borderBottom = "1px solid red";
                error.style.display = "block";
                fine = false;
            }
          });

          if(fine){
             if(!email.value.includes("@")){
                fine = false;
                email.style.borderBottom = "1px solid red";
                error.style.display = "block";
                error.innerHTML = "l'Email inserita non è corretta";
            }
            else{
                error.innerHTML ="controlla per cortesia di aver compilato correttamente tutti i campi";
            }
        }
          return fine;
    }

    componentWillMount(){
        try {
            let burger = document.querySelector("#root > div > div:nth-child(1) > div:nth-child(3) > div");
            burger.style.width = 0;
        } catch (error) {
            
        }
    }

    componentWillUnmount(){
        try {
            let burger = document.querySelector("#root > div > div:nth-child(1) > div:nth-child(3) > div");
            burger.style.width = "36px";
        } catch (error) {
            
        }
    }

    render() {

        return (
            <div className="loginBody">    
            <p className="loginsideTitle">Resource NET</p>
            <div id="circle"><i className="fas fa-chevron-left"></i></div>
            
            <div className="leftLoginSide">
                <div className="signTitle">
                    <h1>Log In</h1>
                    <p>( tutti i campi sono obbligatori )</p>
                </div>
                
                <div id="formLogin">
                    <input id="inputLoginEmail" className="fullInputSignIn" onChange={this.updateValue.bind(this,"Email")} onFocus={this.changeColor.bind(this)} placeholder="Indirizzo e-mail" type="email"/>
                    <input id="inputLoginPW" className="fullInputSignIn" onChange={this.updateValue.bind(this,"Password")} onFocus={this.changeColor.bind(this)} placeholder="Password" type="password"/>
                </div>
                <button className="passwordForgot">Hai dimenticato la password?</button>
                <p id="inputErrorLogin">controlla per cortesia di aver compilato correttamente tutti i campi</p>
                <button id="submitLogin" onClick={this.login}>Accedi</button>
            </div>
            <div className="rightLoginSide">
                <h2 className="welcomeText">Bentornato</h2>
                <p className="welcomeP">Inserisci i tuoi dati per tornare a navigare sulla piattaforma!</p>
            </div>
        </div>   
        )
    }
}
