import React, { Component } from 'react'
import './styles/index.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Lottie from 'react-lottie';
import * as IndexLottie from '../assets/home.json';
import { Link } from 'react-router-dom';


const lottieOptions = {
    loop: true,
    autoplay: true,  
    animationData: IndexLottie.default,
    rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
    }
};   

export default class index extends Component {
    
    constructor(props){
        super(props);
        this.state = {visible:false, data:{nome:"Undefined"}};
    }

    componentDidMount(){
        AOS.init();
        try {
            document.querySelector("#root > div > div:nth-child(1) > div:nth-child(3) > div").style.display = "none";

        } catch (error) {}
    }

    render() {


        return (
            <div className="indexHome">
                <p className="sideTitle indexResource">Resource NET</p>
                <div className="indexTextContainer">
                    <h1 className="indexTitle">Benvenuto</h1>
                    <p>Effettua il login o registrati per iniziare a navigare sulla piattaforma</p>
                    <div className="indexButtonsContainer">
                        <Link to="/registrazione"><button  className="indexleftButton">Sign In</button></Link>
                        <Link to="/login"><button  className="indexrightButton">Log In</button></Link>
                    </div>
                </div>
                <div className="indexLottie">
                    <Lottie id="indexLottie" options={lottieOptions}
                        isStopped={this.state.isStopped}
                        isPaused={this.state.isPaused}/>
                </div>
                <p id="indexFooter">© 2018 - LuMi - All Rights Reserved - Via Ciovassino 3/A 20121 Milan - PIVA 03906530781</p>
            </div>
        )
    }
}
