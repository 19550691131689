import React, { useState } from 'react';
import './styles/sideMenu.css';
import { Link } from 'react-router-dom';
import { Modal } from 'antd'
import AddJobPosition from './addJob'

export default function SideMenu(props) {

const [visible, setVisible] = useState(false);
    try {
        if(window.innerWidth < 1255){
            document.querySelector("#root > div > div:nth-child(1) > div:nth-child(3) > div").style.display = "block";
        }
    } catch (error) {
        
    }
            
   const setModalVisible = (visible) => {
        setVisible(visible);
      }
            
    return (
        <React.Fragment>{
            window.innerWidth < 1255 ? window.location.pathname.includes('info/') ? props.isMine ? <Link className="backArrow" to="/myads"><i style={{fontSize:"30px"}} className="fas fa-angle-left"></i></Link> : <Link className="backArrow" to="/home"><i style={{fontSize:"30px"}} className="fas fa-angle-left"></i></Link> : '' :
            <div className="tabs">
                <div className="face tab">
                    <div><p>{props.nome.charAt(0)}</p></div>
                    <p className="name">{props.nome}</p>
                </div>
                <div className="listContainer">
                    
                    <div className="sideListContainer">
                        <Link to="/home">
                            <div id="homeLi" className={props.home}>
                                <i className="fas fa-home"></i>
                                <p>Home</p>
                            </div>
                        </Link>
                        { props.isMine === false ?  props.job ? <div className="line active tab low">
                          <i className="fas fa-angle-right"></i>
                            <p>{props.job}</p>
                        </div> : '' : ''}
                    </div>
                    <div className="sideListContainer">
                        <Link to="/myads">
                            <div id="adsLi" className={props.ads}>
                                <i className="far fa-clone"></i>
                                <p>I miei Annunci</p>
                            </div>
                        </Link>
                        { props.isMine === true ?  props.job ? <div className="line active tab low">
                          <i className="fas fa-angle-right"></i>
                            <p>{props.job}</p>
                        </div> : '' : ''}
                    </div>
                    <Link to="/candidature">
                        <div id="candidatureLi" className={props.candidature}>
                            <i className="far fa-address-card"></i>
                            <p>Candidature</p>
                        </div>
                    </Link>

                    <Link to="/candidati">
                        <div id="candidatiLi" className={props.candidati}>
                            <i className="far fa-address-card"></i>
                            <p>HR - Candidati</p>
                        </div>
                    </Link>

                    <button onClick={() => setModalVisible(true)} id="addJobPositionButton"className="tab"><i className="fas fa-plus"></i><p> Crea Annuncio</p></button>       
                </div>
                <Link to="/login" onClick={()=>sessionStorage.clear()}><div className="tab" style={{justifyContent: "center"}}>
                    <i className="fas fa-sign-out-alt" style={{marginRight: "20px", color: "rgb(143, 142, 142)"}}></i>
                    <p style={{color: "grey"}}>Logout</p>
                </div></Link>

                <Modal
                    centered
                    maskClosable={false}
                    closable={false}
                    footer={<button onClick={() => setModalVisible(false)} style={{padding:"14px 30px"}} id="okModalButton">Chiudi</button>}
                    visible={visible}
                    width="80%"
                    style={{margin:"50px 0"}}
                    >
                    <AddJobPosition closeModal={setModalVisible}/>
                </Modal>

        </div>}
        </React.Fragment>
    )
}
