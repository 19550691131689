import React, { Component } from 'react';
import Lottie from 'react-lottie';
import './styles/pending.css';
import * as EmailLottie from '../assets/email.json';
import * as AlertLottie from '../assets/alert.json';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import Provider from './providerFE.jsx';

export default class componentName extends Component {

constructor(props){
    super(props);
    this.state = {isStopped: false, isPaused: false, all:{Email:"",response:{log:""}}};
}

componentWillMount(){
  this.setState({all : this.props.location.state.all});
}

componentDidMount() {
    AOS.init();
    this.setState({isStopped: false});
    if(this.state.all.response.log === "User Inserted")
    this.sendEmail();
  }

sendEmail(){
  let provider = new Provider();
  provider.sendEmail(this.state.all.Email,this.state.all.Nome);
}
  
  render() {
   
    const emailOptions = {
        loop: false,
        autoplay: false,  
        animationData: EmailLottie.default,
        rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const alertOptions = {
      loop: false,
      autoplay: false,  
      animationData: AlertLottie.default,
      rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
      }
    };

    switch (this.state.all.response.log) {
      case "Email Already Exist":
        return (
          <div className="pendingBody"> 
            <div style={{padding:"0px"}} className="pendingEmail">
              <Lottie options={alertOptions}
                    speed={0.8}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}/>
           </div>
              <h1 style={{marginTop: "0px"}} data-aos="fade-right" data-aos-duration="800" data-aos-offset="-2000" data-aos-easing="ease-out-quart" data-aos-delay="800" >Si è verificato un errore</h1>   
              <div data-aos="fade-up" data-aos-duration="800" data-aos-offset="-2000" data-aos-easing="ease-out-quart" data-aos-delay="1100">
                <p>E' già presente un account registrato con la seguente e-mail :</p>
                <p><strong>{this.state.all.Email}</strong></p>
                <p>Clicca sul pulsante Torna Indietro per tornare alla fase di registrazione</p> 
              </div>
              <div className="pendingButtonsContainer">
               <Link to="/registrazione"><button data-aos="zoom-in-up" data-aos-duration="500" data-aos-offset="-2000" data-aos-delay="1400" className="pendingButton error">Torna indietro</button></Link>
                <button data-aos="zoom-in-up" data-aos-duration="500" data-aos-offset="-2000" data-aos-delay="1700" className="pendingButtonSupport">Supporto Tecnico</button>
              </div>
        </div>
        )
      case "User Inserted":
        return (
          <div className="pendingBody"> 
            <div className="pendingEmail">
              <Lottie options={emailOptions}
                    speed={0.9}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}/>
           </div>
              <h1 data-aos="fade-right" data-aos-duration="800" data-aos-offset="-2000" data-aos-easing="ease-out-quart" data-aos-delay="1200" >Conferma il tuo indirizzo Email</h1>   
              <div data-aos="fade-up" data-aos-duration="800" data-aos-offset="-2000" data-aos-easing="ease-out-quart" data-aos-delay="1600">
                <p>Abbiamo appena inviato una richiesta di verifica all'indirizzo :</p>
                <p><strong>{this.state.all.Email}</strong></p>
                <p>Clicca sul link all'interno del messaggio per completare la tua registrazione</p> 
              </div>
              <div className="pendingButtonsContainer">
                <button style={{marginLeft: "0px"}} data-aos="zoom-in-up" data-aos-duration="500" data-aos-offset="-2000" data-aos-delay="2100" className="pendingButtonSupport">Supporto Tecnico</button>
              </div>
        </div>
        );
      default:
        //console.log(this.state.all.response);
        return (
          <div className="pendingBody"> 
            <div style={{padding:"0px"}} className="pendingEmail">
              <Lottie options={alertOptions}
                    speed={0.8}
                    isStopped={this.state.isStopped}
                    isPaused={this.state.isPaused}/>
           </div>
              <h1 style={{marginTop: "0px"}} data-aos="fade-right" data-aos-duration="800" data-aos-offset="-2000" data-aos-easing="ease-out-quart" data-aos-delay="800" >Si è verificato un errore</h1>   
              <div data-aos="fade-up" data-aos-duration="800" data-aos-offset="-2000" data-aos-easing="ease-out-quart" data-aos-delay="1100">
                <p>Si è verificato un errore durante la registrazione.</p>
                <p>Clicca sul pulsante Torna Indietro per tornare alla fase di registrazione o contatta il supporto tecnico se il problema persiste</p> 
              </div>
              <div className="pendingButtonsContainer">
               <Link to="/registrazione"><button data-aos="zoom-in-up" data-aos-duration="500" data-aos-offset="-2000" data-aos-delay="1400" className="pendingButton error">Torna indietro</button></Link>
                <button data-aos="zoom-in-up" data-aos-duration="500" data-aos-offset="-2000" data-aos-delay="1700" className="pendingButtonSupport">Supporto Tecnico</button>
              </div>
        </div>
        );
    }
  }
}
