import React, { Component } from 'react'
import SideMenu from './SideMenu.jsx'
import Provider from './providerFE.jsx'
import { Modal, Card, Select } from 'antd';
import { Link } from 'react-router-dom';
import './styles/home.css';
import LazyLoad from "react-lazyload";

const { Option } = Select;

export default class home extends Component {
    
    constructor(props){
        super(props);
        this.state = {visible: false,data:{nome:"loadingDataName"},cards:[],status:"Active"};
    }

    setModalVisible(visible) {
        this.setState({ visible });
      }

    componentWillMount(){
        if (this.props.location.state !== undefined)
            this.setState({data:this.props.location.state.data});
            else if(sessionStorage.getItem("nome")){
                this.setState({data:{nome:sessionStorage.getItem("nome"),
                    token:sessionStorage.getItem("token"),
                    cognome:sessionStorage.getItem("cognome"),
                    email:sessionStorage.getItem("email"),
                    nome_azienda:sessionStorage.getItem("nome_azienda"),
                    id:sessionStorage.getItem("id"),
                    gruppo:sessionStorage.getItem("gruppo"),
                    organizzazione:sessionStorage.getItem("organizzazione")
                    }});

                    let provider = new Provider();
                    let formData = new FormData();
                    formData.append("token",sessionStorage.getItem('token'));
                    formData.append("id",sessionStorage.getItem('id'));  
                    provider.updateToken(formData).then(data => {  
                        let response = JSON.parse(data);
                        switch (response.log) {
                            case 'sessione scaduta':
                                this.setModalVisible(true);
                                sessionStorage.clear();                                
                                break;
                            default:
                                break;
                        }
                    });
                    
            }else{
               
                this.props.history.push({
                    pathname: '/'
                });
            }
    }

    

    getCards = () => {

        let formData = new FormData();
        formData.append("organizzazione",sessionStorage.getItem('organizzazione'));
        formData.append("page","home");   
   
        let provider = new Provider();
        
         provider.getCards(formData).then(data => {       
            this.setState({cards: data});
            //console.log(this.state.cards);            
        });
    }

    getClosedCards = () => {

        let formData = new FormData();
        //formData.append("id",sessionStorage.getItem('id'));
        formData.append("organizzazione",sessionStorage.getItem('organizzazione'));
        formData.append("page","homeClosed");   
   
        let provider = new Provider();
        
         provider.getCards(formData).then(data => {       
            this.setState({cards: data});
            //console.log(this.state.cards);            
        });
    }

    changeCards(value) {
        //console.log(`selected ${value}`);
        this.setState({status:value});
        if(value === "Active")
        this.getCards();
        else
        this.getClosedCards();
      }

    componentDidMount(){
        try {
        if(sessionStorage.getItem("nome"))
            this.getCards();
        } catch (error) {}
    }
    render() {

        try {
            if(this.state.data.nome !== "loadingDataName" ){
                sessionStorage.setItem("nome",this.state.data.nome);
                sessionStorage.setItem("token",this.state.data.token);
                sessionStorage.setItem("cognome",this.state.data.cognome);
                sessionStorage.setItem("email",this.state.data.email);
                sessionStorage.setItem("nome_azienda",this.state.data.nome_azienda);
                sessionStorage.setItem("id",this.state.data.id);
                sessionStorage.setItem("gruppo",this.state.data.gruppo);
                sessionStorage.setItem("organizzazione", this.state.data.organizzazione);
                }
            } catch (error) {}
        
let cards;
if(this.state.cards.length){
    if(this.state.status === "Active"){
    cards = this.state.cards.map(item => (
        <LazyLoad key={item.job_value}>
            <Link className="homeCardRedirect" to={"/info/"+item.job_value}>
                <Card title={item.title} 
                  hoverable
                  extra={ <p style={{color:"orange"}}>+ info</p> } 
                  style={{ width: "100%"}}>
                    <p>Status : <span style={{color:"#00cc00"}}>Active</span></p>
                    <p>Durata : {item.start_date} / {item.end_date}</p>
                    <p>Risorse Necessarie : {item.posizioni_disponibili}</p>
                    <p>Sede : {item.location}</p>
                </Card>
            </Link>
        </LazyLoad>))
        }else{
        cards = this.state.cards.map(item => (
            <LazyLoad key={item.job_value}>
                <Link className="homeCardRedirect" to={"/info/"+item.job_value}>
                    <Card title={item.title} 
                      hoverable
                      extra={ <p style={{color:"orange"}}>+ info</p> } 
                      style={{ width: "100%"}}>
                        <p>Status : <span style={{color:"red"}}>Closed</span></p>
                        <p>Durata : {item.start_date} / {item.end_date}</p>
                        <p>Posizioni ancora disponibili : {item.posizioni_disponibili}</p>
                        <p>Sede : {item.location}</p>
                    </Card>
                </Link>
            </LazyLoad>))
        }
}
                   
        return (
            <div className="homeBody">
                <SideMenu home="tab line active" ads="tab other line" candidature="tab other line" candidati="tab other line" nome={sessionStorage.getItem('nome')+" "+sessionStorage.getItem('cognome')}/>
                <div className="homeContentAll">
                   <h1 className="homeContenth1">Annunci Recenti</h1>
                   <div className="homeContentBody">
                    {cards || ""}
                    </div>
                    <Select style={{position:"absolute",right:"40px",top:"35px",fontSize:"20px"}} onChange={this.changeCards.bind(this)} defaultValue="Active">
                        <Option value="Active">Active</Option>
                        <Option value="Closed">Closed</Option>
                    </Select>
                </div>
                
                
                <Modal
                    title="Si è verificato un errore"
                    centered
                    maskClosable={false}
                    closable={false}
                    footer={<Link to="/login"><button id="okModalButton">Ok</button></Link>}
                    visible={this.state.visible}
                    >
                    <p>La sessione è scaduta. Ti preghiamo di accedere nuovamente per continuare ad utilizzare la piattaforma</p>
                    
                </Modal>
            </div>
        )
    }
}
