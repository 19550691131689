import { Button, Modal, Table } from "antd";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { apiConfig } from "../config/apiConfig";
import SideMenu from "./SideMenu";
import UpdateCandidate from "./updateCandidate.jsx";

const Candidato = () => {
  const location = useLocation();
  const { item: originalItem } = location.state || {};

  const { id } = useParams();
  const item = originalItem ? { ...originalItem, id } : null;

  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchFiles = async () => {
      const response = await fetch(`${apiConfig.candidates}/${id}/files`);
      const fileUrls = await response.json();
      setFiles(fileUrls);
      console.log(fileUrls);
    };
    fetchFiles();
  }, [id]);

  const columns = [
    {
      title: "Campo",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Valore",
      dataIndex: "value",
      key: "value",
    },
  ];

  const formatDateFields = (item, dateFields) => {
    return Object.entries(item).reduce((acc, [key, value]) => {
      if (dateFields.includes(key) && typeof value === "string") {
        try {
          const formattedDate = format(new Date(value), "dd-MM-yyyy");
          acc[key] = formattedDate;
        } catch (error) {
          acc[key] = value;
        }
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
  };

  const excludeFields = (item, fieldsToExclude) => {
    return Object.entries(item)
      .filter(([key]) => !fieldsToExclude.includes(key))
      .map(([key, value], index) => ({
        key: index,
        field: key,
        value: value !== "undefined" ? value : "",
      }));
  };

  const fieldsToExclude = ["id", "inserted_by"];
  const dateFields = ["data_di_nascita", "disponibile_dal", "disponibile_al"];
  const formattedItem = item ? formatDateFields(item, dateFields) : {};
  const dataSource = formattedItem
    ? excludeFields(formattedItem, fieldsToExclude)
    : [];

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const replaceUndefinedWithEmptyString = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        key,
        value === "undefined" ? "" : value,
      ])
    );
  };

  const transformedItem = item ? replaceUndefinedWithEmptyString(item) : {};

  return (
    <div>
      <button
        id="addJobPositionButton"
        className="tab"
        style={{ marginLeft: "400px" }}
        onClick={showModal}
      >
        <i className="fas fa-plus"></i>
        <p>Modifica Candidato</p>
      </button>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        {item ? (
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            bordered
            style={{
              width: "80%",
              maxWidth: "800px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              marginLeft: "400px",
            }}
          />
        ) : (
          <p>Nessun dato disponibile</p>
        )}
      </div>
      <div style={{ marginTop: "20px", textAlign: "center", width: "125%" }}>
        {files.length > 0 && (
          <p style={{ fontWeight: "bold", marginBottom: "10px" }}>
            File allegati:
          </p>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "15px",
          }}
        >
          {files.map((file, index) => (
            <a
              key={index}
              href={apiConfig.candidateFileByName(id, file.fileName)}
              download={file.fileName}
              style={{
                padding: "10px 20px",
                backgroundColor: "#f0f0f0",
                borderRadius: "5px",
                textDecoration: "none",
                color: "#333",
                transition: "background-color 0.3s",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#e0e0e0")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "#f0f0f0")
              }
            >
              {file.fileName}
            </a>
          ))}
        </div>
      </div>
      <SideMenu
        home="tab line other"
        ads="tab other line"
        candidature="tab other line"
        candidati="tab other line"
        nome={
          sessionStorage.getItem("nome") +
          " " +
          sessionStorage.getItem("cognome")
        }
      />
      <Modal
        title="Si è verificato un errore"
        centered
        maskClosable={false}
        closable={false}
        footer={
          <Link to="/login">
            <button id="okModalButton">Ok</button>
          </Link>
        }
      >
        <p>
          La sessione è scaduta. Ti preghiamo di accedere nuovamente per
          continuare ad utilizzare la piattaforma
        </p>
      </Modal>

      <Modal
        title="Resource NET"
        visible={isModalVisible}
        onCancel={handleCancel}
        width="80%"
        cancelText="Annulla"
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Annulla
          </Button>,
        ]}
      >
        <UpdateCandidate item={transformedItem} />
      </Modal>
    </div>
  );
};

export default Candidato;
