import React, { Component } from 'react'
import { Modal, DatePicker, Input, Select, TimePicker, AutoComplete } from 'antd';
import 'antd/dist/antd.css';
import './styles/addjob.css';
import Provider from './providerFE.jsx';
import locale from 'antd/es/date-picker/locale/it_IT';
import { Link } from 'react-router-dom'
import moment from 'moment'

export default class addJob extends Component {
    constructor(props){
        super(props)
        this.state = {visible: false,
            Tariffa:'',
            Tariffa_type:"Tariffa Oraria",
            Orario:'',
            Skills_secondarie:"",
            Skills_terziarie:'',
            Skills_non_obbligatorie:'',
            Commenti:'',
            Settore:"",
            options:[]
        };
    }

    getCompetenze(){
        let provider = new Provider();
           provider.getCompetenze().then(data =>{

            let options = [...this.state.options];
            data.forEach(elem => options.push({ value: elem }));
            this.setState({ options });
        });
    }

    updateCompetenza =  (value,type) =>{

        this.setState({
           [type] : value
       });
   }

    setModalVisible(visible) {
        this.setState({ visible });
      }

      error() {
        Modal.error({
          title: 'Si è verificato un errore',
          content: 'Controlla che tutti i dati inseriti siano corretti e prova nuovamente con la creazione della job position. Se l\'errore dovesse persistere, contattare il supporto tecnico',
        });
      }

       success() {
        Modal.success({
          content: 'Il salvataggio è stato effettuato',
        });
      }

      inserted() {
        Modal.success({
          content: 'La tua job position è stata inserita'
        });
      }

    componentDidMount(){
        this.setState({id:sessionStorage.getItem("id"),token:sessionStorage.getItem("token")});
        document.querySelector("#formAddJob > div:nth-child(3) > div > input")?.addEventListener("focus",this.changeColor);
        document.querySelector("#formAddJob > div:nth-child(4) > div > input")?.addEventListener("focus",this.changeColor);
        if(this.props.defaultValues)
        this.passAll();
    }


    insertJob = () =>{

        let formCompleted = this.check();
        if(!formCompleted) return;
        //console.log(this.state);
        let formData = new FormData();
        formData.append("data",JSON.stringify(this.state).toString());   
        if(this.props.modify){
        formData.append("modify",this.props.modify);  
        formData.append("job_value",this.props.defaultValues.job_value);
        } 

        //console.log(formData.get("data"));
        let provider = new Provider();
        
        provider.insertJobPosition(formData).then(data => {
            
            //console.log(data);
            let response = JSON.parse(data);
            switch (response.log) {
                case "sessione scaduta":
                    this.setModalVisible(true)
                    sessionStorage.clear();
                    break;
                case "data error":
                    this.error()
                    break;
                case "job position inserita":
                    this.inserted();
                    this.props.closeModal();
                    this.sendEmailNewJP();
                    break;
                case "job position modificata":
                    this.success();
                    this.sendEmailChangeJP();
                    break;
                default:
                    console.log(response.log);
                    break;
            }
        });

    }

    sendEmailNewJP = () =>{
        let formData = new FormData();
        formData.append("type","new_JP");
        formData.append("id",sessionStorage.getItem("id"));
        formData.append("nome_JP",this.state.Title);

        let provider = new Provider();
        
        provider.sendInfoEmail(formData).then(data => {
            console.log(data);
        });
    }

    sendEmailChangeJP = () =>{
        let formData = new FormData();
        formData.append("type","modify_JP");
        formData.append("id",sessionStorage.getItem("id"));
        formData.append("nome_JP",this.state.Title);

        let provider = new Provider();
        
        provider.sendInfoEmail(formData).then(data => {
            console.log(data);
        });
    }
    
    
    passAll = () =>{
        this.setState({Title:this.props.defaultValues.title});
        this.setState({Posizioni_disponibili:this.props.defaultValues.posizioni_disponibili});
        this.setState({Tariffa:this.props.defaultValues.tariffa});
        this.setState({Tariffa_type:this.props.defaultValues.rate_type});
        this.setState({Data_inizio:this.props.defaultValues.start_date});
        this.setState({Data_fine:this.props.defaultValues.end_date});
        this.setState({Orario:this.props.defaultValues.orario});
        this.setState({Sede:this.props.defaultValues.location});
        this.setState({Settore:this.props.defaultValues.settore});
        this.setState({Dettagli:this.props.defaultValues.details});
        this.setState({Skills_principali:this.props.defaultValues.primary_skills});
        this.setState({Skills_secondarie:this.props.defaultValues.secondary_skills});
        this.setState({Skills_terziarie:this.props.defaultValues.tertiary_skills});
        this.setState({Skills_non_obbligatorie:this.props.defaultValues.nice_to_have});
        this.setState({Commenti:this.props.defaultValues.comments});
        this.setState({Status:this.props.defaultValues.status});
    }

    changeTariffa(value) {
        //console.log(`selected ${value}`);
        this.setState({Tariffa_type:value});
    }

    changeStatus(value) {
        //console.log(`selected ${value}`);
        this.setState({Status:value});
    }

    saveDataInizioFine(){
        let dataInizio =  document.querySelector("#formAddJob > div:nth-child(3) > div > input");
        let dataFine = document.querySelector("#formAddJob > div:nth-child(4) > div > input");
        this.setState({Data_inizio:dataInizio.value});
        this.setState({Data_fine:dataFine.value});
    }

    saveTimeDalleAlle(){
        let dalle =  document.querySelector("#formAddJob > div.ant-picker.ant-picker-range.halfInputAddJob > div:nth-child(1) > input");
        let alle = document.querySelector("#formAddJob > div.ant-picker.ant-picker-range.halfInputAddJob > div:nth-child(3) > input");
        this.setState({Orario:"Dalle "+dalle.value+" Alle "+alle.value});
    }
    
    changeColor(e) {
        e.target.style.borderBottom = "1px solid #009dd6";
    }

    updateValue =  (element,event) =>{
       
        this.setState({
           [element] : event.target.value
        });

            //console.log(this.state);
        
    }

    check() {
        let title = document.getElementById('inputAddJobTitle');
        let posizioni_disponibili = document.getElementById('inputAddJobRisorseNecessarie');
        let dataInizio =  document.querySelector("#formAddJob > div:nth-child(3) > div > input");
        let dataFine = document.querySelector("#formAddJob > div:nth-child(4) > div > input");
        let sede = document.getElementById('inputAddJobSede');
        let dettagli = document.getElementById('inputAddJobDettagli');
        let skillsPrincipali = document.getElementById('inputAddJobSPrincipali');
        let error = document.getElementById('inputErrorAddJob');
        let fine = true;
        error.style.display = "none";
        error.innerHTML = "controlla per cortesia di aver compilato correttamente tutti i campi obbligatori";


        if(title.value === "" || title.value == null){
            title.style.borderBottom = "1px solid red";
                error.style.display = "block";
                fine = false;
        }
       
        if(dataInizio.value === "" || dataInizio.value == null){
            dataInizio.style.borderBottom = "1px solid red";
                error.style.display = "block";
                fine = false;
        }
        
        if(dataFine.value === "" || dataFine.value == null){
            dataFine.style.borderBottom = "1px solid red";
                error.style.display = "block";
                fine = false;
        }

        if(sede.value === "" || sede.value == null){
            sede.style.borderBottom = "1px solid red";
                error.style.display = "block";
                fine = false;
        }
        if(dettagli.value === "" || dettagli.value == null){
            dettagli.style.borderBottom = "1px solid red";
                error.style.display = "block";
                fine = false;
        }
        if(skillsPrincipali.value === "" || skillsPrincipali.value == null){
            skillsPrincipali.style.borderBottom = "1px solid red";
                error.style.display = "block";
                fine = false;
        }
        if(posizioni_disponibili.value === "" || posizioni_disponibili.value == null || isNaN(posizioni_disponibili.value)){
            posizioni_disponibili.style.borderBottom = "1px solid red";
            error.style.display = "block";
            error.innerHTML = "il campo <strong>risorse necessarie</strong> richiede l'inserimento di un numero in cifre";
            fine = false;
        }
          return fine;
    }

    componentWillMount(){
        if(sessionStorage.getItem('nome')){
            let provider = new Provider();
            let formData = new FormData();
            formData.append("token",sessionStorage.getItem('token')); 
            formData.append("id",sessionStorage.getItem('id')); 
            provider.updateToken(formData).then(data => {  
                let response = JSON.parse(data);
                switch (response.log) {
                    case 'sessione scaduta':
                        this.setModalVisible(true);
                        sessionStorage.clear();
                        break;
                    default:
                        this.getCompetenze();
                        break;
                }
            });
        }else{ 
            
            this.props.history.push({
                pathname: '/'
            }); }
    }

    render() {
        const { Option } = Select;
        const { RangePicker } = TimePicker;
        const { TextArea } = Input;

        let orario;
        if(this.props.defaultValues){
            orario = this.props.defaultValues.orario.replace('Dalle ','');
            orario = orario.replace('Alle ','');
            orario = orario.split(' ');
        }

        return (
            <div id="allJobPBody" className="AddJobBody">    
            <p className="sideTitle">{this.props.defaultValues ? '': "Resource NET"}</p>
            <div className="addjobformBody">
                <div className="signTitle">
                    <h1>{this.props.modify ? "Modifica la Job Position" : "Aggiunta di una Job Position"}</h1>
                    <p>{this.props.modify ? "" : "( i campi con * sono obbligatori )"}</p>
                </div>
                {this.props.defaultValues ? <div style={{position:"absolute",right:"40px",top:"35px",fontSize:"17px"}}>Status JobPosition : <Select style={{fontSize:"20px"}} onChange={this.changeStatus.bind(this)} defaultValue={this.props.defaultValues.status}>
                        <Option value="Active">Active</Option>
                        <Option value="Closed">Closed</Option>
                    </Select></div> : ''}
                <div id="formAddJob">
                    <input id="inputAddJobTitle" defaultValue={this.props.defaultValues ? this.props.defaultValues.title : ""} className="halfInputAddJob" onChange={this.updateValue.bind(this,"Title")} onFocus={this.changeColor.bind(this)} placeholder="* Titolo Descrittivo" type="Text"/>
                    <input id="inputAddJobRisorseNecessarie" defaultValue={this.props.defaultValues ? this.props.defaultValues.posizioni_disponibili : ""} className="halfInputAddJob" onChange={this.updateValue.bind(this,"Posizioni_disponibili")} onFocus={this.changeColor.bind(this)} placeholder="* Quantità risorse necessarie" type="number"/>
                    <DatePicker defaultValue={this.props.defaultValues ? moment(this.props.defaultValues.start_date) : ""} onBlur={this.saveDataInizioFine.bind(this)} locale={locale} allowClear={true}  placeholder="* Inizio" className="halfInputAddJob"/>
                    <DatePicker defaultValue={this.props.defaultValues ? moment(this.props.defaultValues.end_date) : ""} onBlur={this.saveDataInizioFine.bind(this)} locale={locale} allowClear={true}  placeholder="* Fine" className="halfInputAddJob"/>
                    <Input.Group className="halfInputAddJob" style={{float:"right"}} compact >
                    <Input defaultValue={this.props.defaultValues ? this.props.defaultValues.tariffa : ""} style={{ width: '40%' }} type="number" onChange={this.updateValue.bind(this,"Tariffa")} placeholder="Prezzo Tariffa" suffix="€" />
                    <Select style={{width: "60%"}} onChange={this.changeTariffa.bind(this)} defaultValue={this.props.defaultValues ? this.props.defaultValues.rate_type : "Tariffa Oraria"}>
                        <Option value="Tariffa Oraria">Tariffa Oraria</Option>
                        <Option value="Tariffa Giornaliera">Tariffa Giornaliera</Option>
                    </Select>
                    </Input.Group>
                    <RangePicker defaultValue={this.props.defaultValues ? (this.props.defaultValues.orario !== "") ?[moment(orario[0],'LT'),moment(orario[1],'LT')] : ["",""] : ["",""]} onCalendarChange={this.saveTimeDalleAlle.bind(this)} className="halfInputAddJob" format="HH:mm" minuteStep={15} placeholder={["Dalle","Alle"]}/>
                    <input id="inputAddJobSede" defaultValue={this.props.defaultValues ? this.props.defaultValues.location : ""} className="halfInputAddJob" onChange={this.updateValue.bind(this,"Sede")} onFocus={this.changeColor.bind(this)} placeholder="* Sede lavorativa" type="Text"/>
                    <input id="inputAddJobSettore" defaultValue={this.props.defaultValues ? this.props.defaultValues.settore : ""} className="halfInputAddJob" onChange={this.updateValue.bind(this,"Settore")} onFocus={this.changeColor.bind(this)} placeholder="Settore" type="Text"/>

                    <TextArea maxLength={2550} defaultValue={this.props.defaultValues ? this.props.defaultValues.details : ""} id="inputAddJobDettagli" className="fullInputAddJob" onChange={this.updateValue.bind(this,"Dettagli")} onFocus={this.changeColor.bind(this)} placeholder="* Dettagli" autoSize={{ minRows: 1 }} />
                    
                    <AutoComplete
                            options={this.state.options}
                            defaultValue={this.props.defaultValues ? this.props.defaultValues.primary_skills : ""}
                            id="inputAddJobSPrincipali"
                            onChange={(inputValue) => this.updateCompetenza(inputValue,"Skills_principali")}
                            onFocus={this.changeColor.bind(this)}
                            placeholder="* Skills Principali"
                            style={{fontSize:"16px", fontWeight:500, color: "black", float: "left",width: '45%',border:'none',boxShadow:"none",margin:"15px 2.5%",textAlign:"left",/*borderBottom:"1px solid #009dd6"*/}}
                            filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    <AutoComplete
                        options={this.state.options}
                        defaultValue={this.props.defaultValues ? this.props.defaultValues.secondary_skills : ""}
                        id="inputAddJobSSecondarie"
                        onChange={(inputValue) => this.updateCompetenza(inputValue,"Skills_secondarie")}
                        onFocus={this.changeColor.bind(this)}
                        placeholder="Skills Secondarie"
                        style={{fontSize:"16px", fontWeight:500, color: "black", float: "right",width: '45%',border:'none',boxShadow:"none",margin:"15px 2.5%",textAlign:"left",/*borderBottom:"1px solid #009dd6"*/}}
                        filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                    <AutoComplete
                        options={this.state.options}
                        defaultValue={this.props.defaultValues ? this.props.defaultValues.tertiary_skills : ""}
                        id="inputAddJobSTerziarie"
                        onChange={(inputValue) => this.updateCompetenza(inputValue,"Skills_terziarie")}
                        onFocus={this.changeColor.bind(this)}
                        placeholder="Skills Terziarie"
                        style={{fontSize:"16px", fontWeight:500, color: "black", float: "left",width: '45%',border:'none',boxShadow:"none",margin:"15px 2.5%",textAlign:"left",/*borderBottom:"1px solid #009dd6"*/}}
                        filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                    <AutoComplete
                        options={this.state.options}
                        defaultValue={this.props.defaultValues ? this.props.defaultValues.nice_to_have : ""}
                        id="inputAddJobSNoObbligo"
                        onChange={(inputValue) => this.updateCompetenza(inputValue,"Skills_non_obbligatorie")}
                        onFocus={this.changeColor.bind(this)}
                        placeholder="Skills non obbligatorie"
                        style={{fontSize:"16px", fontWeight:500, color: "black", float: "right",width: '45%',border:'none',boxShadow:"none",margin:"15px 2.5%",textAlign:"left",/*borderBottom:"1px solid #009dd6"*/}}
                        filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                        
                    {/*<input id="inputAddJobSPrincipali" defaultValue={this.props.defaultValues ? this.props.defaultValues.primary_skills : ""} className="halfInputAddJob" onChange={this.updateValue.bind(this,"Skills_principali")} onFocus={this.changeColor.bind(this)} placeholder="* Skills Principali" type="Text"/>
                    <input id="inputAddJobSSecondarie" defaultValue={this.props.defaultValues ? this.props.defaultValues.secondary_skills : ""} className="halfInputAddJob" onChange={this.updateValue.bind(this,"Skills_secondarie")} onFocus={this.changeColor.bind(this)} placeholder="Skills Secondarie" type="Text"/>
                    <input id="inputAddJobSTerziarie" defaultValue={this.props.defaultValues ? this.props.defaultValues.tertiary_skills : ""} className="halfInputAddJob" onChange={this.updateValue.bind(this,"Skills_terziarie")} onFocus={this.changeColor.bind(this)} placeholder="Skills Terziarie" type="Text"/>
                    <input id="inputAddJobSNoObbligo" defaultValue={this.props.defaultValues ? this.props.defaultValues.nice_to_have : ""} className="halfInputAddJob" onChange={this.updateValue.bind(this,"Skills_non_obbligatorie")} onFocus={this.changeColor.bind(this)} placeholder="Skills non obbligatorie" type="Text"/>*/}
                    
                    <TextArea maxLength={2550} defaultValue={this.props.defaultValues ? this.props.defaultValues.comments : ""} className="fullInputAddJob" onChange={this.updateValue.bind(this,"Commenti")} onFocus={this.changeColor.bind(this)} placeholder="Commenti aggiuntivi" autoSize={{ minRows: 1 }} />

                </div>
                <p id="inputErrorAddJob">controlla per cortesia di aver compilato correttamente tutti i campi obbligatori</p>
                <button id="submitAddJob" onClick={this.insertJob}>{this.props.defaultValues ? "Salva modifiche" : "Aggiungi"}</button>
                <Modal
                    title="Si è verificato un errore"
                    centered
                    maskClosable={false}
                    closable={false}
                    footer={<Link to="/login"><button id="okModalButton">Ok</button></Link>}
                    visible={this.state.visible}
                    >
                    <p>La sessione è scaduta. Ti preghiamo di accedere nuovamente per continuare ad utilizzare la piattaforma</p>
                    
                </Modal>
                

                
            </div>
        </div>   
        )
    }
}
