import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu'
import { Modal } from 'antd'
import AddJobPosition from './addJob'


export default class componentName extends Component {

    constructor(props){
        super(props);
        this.state = {isOpen:false,visible:false}
    }

    handleStateChange(state) {
        this.setState({ isOpen: state.isOpen });
        switch (window.location.pathname) {
            case "/home":
                document.getElementById("homeLi").classList ="tab active line";
                document.getElementById("adsLi").classList ="tab other line";
                document.getElementById("candidatureLi").classList ="tab other line";
                break;
            case "/myads":
                document.getElementById("homeLi").classList ="tab other line";
                document.getElementById("adsLi").classList ="tab active line";
                document.getElementById("candidatureLi").classList ="tab other line";
                break;
            case "/candidature":
                document.getElementById("homeLi").classList ="tab other line";
                document.getElementById("adsLi").classList ="tab other line";
                document.getElementById("candidatureLi").classList ="tab active line";
                break;
            default:
                break;
        }
    }

    setModalVisible(visible) {
        this.setState({ visible });
      }

    closeMenu = () => {
        this.setState({ isOpen: false })
    }

    clearMenu = () =>{
        document.querySelector("#root > div > div:nth-child(1) > div:nth-child(3) > div").style.display = "none";
    }

    logOutActions = () =>{
        sessionStorage.clear();
        this.closeMenu();
        this.clearMenu();
    }

    addJobActions = () =>{
        
        this.closeMenu();
        this.setModalVisible(true);
        //this.clearMenu();
    }

    

  render() {

    
    return (
        <React.Fragment>
            <Menu onStateChange={(state) => this.handleStateChange(state)} isOpen={this.state.isOpen} >
                <div className="face tab">
                    <div><p><i className="far fa-user" style={{margin:0, color:"white"}}></i></p></div>
                    <p className="name">Profilo</p>
                </div>
                <div className="listContainer">
                <div className="sideListContainer">

                    <Link onClick={this.closeMenu.bind(this)} to="/home">
                        <div id="homeLi" className="tab other line">
                            <i className="fas fa-home"></i>
                            <p>Home</p>
                        </div>
                    </Link>
                    </div>
                    <Link onClick={this.closeMenu.bind(this)} to="/myads">
                        <div id="adsLi" className="tab other line">
                            <i className="far fa-clone"></i>
                            <p>I miei Annunci</p>
                        </div>
                    </Link>
                    <Link onClick={this.closeMenu.bind(this)} to="/candidature">
                        <div id="candidatureLi" className="tab other line">
                            <i className="far fa-address-card"></i>
                            <p>Candidature</p>
                        </div>
                    </Link>
                    <button onClick={this.addJobActions.bind(this)} id="addJobPositionButton"className="tab"><i className="fas fa-plus"></i><p> Crea Annuncio</p></button>    
                </div>
                <Link to="/login" onClick={this.logOutActions.bind(this)}><div className="tab" style={{justifyContent: "center"}}>
                    <i className="fas fa-sign-out-alt" style={{marginRight: "20px", color: "rgb(143, 142, 142)"}}></i>
                    <p style={{color: "grey"}}>Logout</p>
                </div></Link>
            </Menu>
            <Modal
                    centered
                    maskClosable={false}
                    closable={false}
                    footer={<button onClick={() => this.setModalVisible(false)} style={{padding:"14px 30px"}} id="okModalButton">Chiudi</button>}
                    visible={this.state.visible}
                    width="80%"
                    style={{margin:"50px 0"}}
                    >
                    <AddJobPosition/>
                </Modal>
            </React.Fragment>
        );
  }
}
