import React, { Component } from 'react'
import './styles/registrazione.css';
import Provider from './providerFE.jsx';

export default class registrazione extends Component {

    constructor(props){
        super(props)
        this.state = {};
    }

    insertUser = () =>{

        let formCompleted = this.check();
        if(!formCompleted) return;
        console.log(JSON.stringify(this.state).toString());

        let formData = new FormData();
        formData.append("data",JSON.stringify(this.state).toString());   
        let provider = new Provider();
        
        provider.registrazione(formData).then(data => {
            //console.log(data);
            this.setState({response: JSON.parse(data)});
            this.props.history.push({
                pathname: '/pending',
                state: { all: this.state }
            });
        });

    }

    
    changeColor(e) {
        e.target.style.borderBottom = "1px solid #009dd6";
    }

    updateValue =  (element,event) =>{
       
        this.setState({
           [element] : event.target.value
        });
        
    }

    check() {
        let form = document.getElementById('formRegistrazione');
        let error = document.getElementById('inputErrorRegistrazione');
        let password = document.getElementById('inputRegistrazionePW');
        let conf_password = document.getElementById('inputRegistrazioneConfPW');
        let email = document.getElementById('inputRegistrazioneEmail');
        let children = form.children;
        let fine = true;
        error.style.display = "none";

        Array.prototype.forEach.call(children, child => {
            if((child.value === "" || child.value == null) && !["inputRegistrazioneSDI", "inputRegistrazioneIban"].includes(child.id)){
                child.style.borderBottom = "1px solid red";
                error.style.display = "block";
                fine = false;
            }
          });

          if(fine){
            if(password.value !== conf_password.value){
                fine = false;
                conf_password.style.borderBottom = "1px solid red";
                error.style.display = "block";
                error.innerHTML = "Password e Conferma Password devono corrispondere";
            }
            else if(!email.value.includes("@")){
                fine = false;
                email.style.borderBottom = "1px solid red";
                error.style.display = "block";
                error.innerHTML = "l'Email inserita non è corretta";
            }
            else{
                error.innerHTML ="controlla per cortesia di aver compilato correttamente tutti i campi";
            }
        }
          return fine;
    }

    render() {
        return (
            <div className="registrazioneBody">    
            <p className="sideTitle">Resource NET</p>
            <div id="circle"><i className="fas fa-chevron-right"></i></div>
            <div className="leftSide">
                <h2 className="welcomeText">Benvenuto</h2>
                <p className="welcomeP">Entra nel Network di aziende in partnership per lo sharing di risorse.
                Compila il form di registrazione. <br/> Richiederà solo pochi minuti!</p>
            </div>
            <div className="rightSide">
                <div className="signTitle">
                    <h1>Registrazione</h1>
                    <p>( tutti i campi sono obbligatori )</p>
                </div>
                
                <div id="formRegistrazione">
                    <input id="inputRegistrazioneNome" className="halfInputSignIn" onChange={this.updateValue.bind(this,"Nome")} onFocus={this.changeColor.bind(this)} placeholder="Nome" type="Text"/>
                    <input id="inputRegistrazioneCognome" className="halfInputSignIn" onChange={this.updateValue.bind(this,"Cognome")} onFocus={this.changeColor.bind(this)} placeholder="Cognome" type="Text"/>
                    <input id="inputRegistrazioneRS" className="fullInputSignIn" onChange={this.updateValue.bind(this,"RagioneSociale")} onFocus={this.changeColor.bind(this)} placeholder="Ragione Sociale" type="Text"/>
                    <input id="inputRegistrazionePI" className="fullInputSignIn" onChange={this.updateValue.bind(this,"PartitaIVA")} onFocus={this.changeColor.bind(this)} maxLength={11} placeholder="Partita IVA" type="Text"/>
                    <input id="inputRegistrazioneIndirizzo" className="halfInputSignIn" onChange={this.updateValue.bind(this,"Indirizzo")} onFocus={this.changeColor.bind(this)} placeholder="Indirizzo" type="Text"/>
                    {/*<input id="inputRegistrazioneSDI" className="halfInputSignIn" onChange={this.updateValue.bind(this,"SDI")} onFocus={this.changeColor.bind(this)} maxLength={7} placeholder="SDI" type="Text"/>*/}
                    {/*<input id="inputRegistrazioneIban" className="fullInputSignIn" onChange={this.updateValue.bind(this,"Iban")} onFocus={this.changeColor.bind(this)} maxLength={27} placeholder="Iban" type="Text"/>*/}
                    <input id="inputRegistrazioneEmail" className="halfInputSignIn" onChange={this.updateValue.bind(this,"Email")} onFocus={this.changeColor.bind(this)} placeholder="Email" type="email"/>
                    <input id="inputRegistrazioneNomeAzienda" className="halfInputSignIn" onChange={this.updateValue.bind(this,"NomeAzienda")} onFocus={this.changeColor.bind(this)} placeholder="Nome dell'azienda" type="Text"/>
                    <input id="inputRegistrazionePW" className="halfInputSignIn" onChange={this.updateValue.bind(this,"Password")} onFocus={this.changeColor.bind(this)} placeholder="Password" type="password"/>
                    <input id="inputRegistrazioneConfPW" className="halfInputSignIn" onFocus={this.changeColor.bind(this)} placeholder="Conferma Password" type="password"/>
                </div>
                <p id="inputErrorRegistrazione">controlla per cortesia di aver compilato correttamente tutti i campi</p>
                <button id="submitRegistrazione" onClick={this.insertUser}>Prosegui</button>
            </div>
        </div>   
        )
    }
}
