import React, { Component } from 'react'
import SideMenu from './SideMenu.jsx'
import Provider from './providerFE.jsx'
import { Modal, Card, Select, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload'

const { Option } = Select;

const plainOptions = ['Contrattempo o imprevisto', 'Cambio di disponibilità', 'Tariffa insufficiente','Motivi familiari/personali'];

export default class candidature extends Component {

    constructor(props){
        super(props);
        this.state = {visible: false,cards:[],status:"Active",reasons:"Motivi personali"};
    }

    setModalVisible(visible) {
        this.setState({ visible });
      }

    componentWillMount(){
        if(sessionStorage.getItem('nome')){
            let provider = new Provider();
            let formData = new FormData();
            formData.append("token",sessionStorage.getItem('token')); 
            formData.append("id",sessionStorage.getItem('id')); 
            provider.updateToken(formData).then(data => {  
                let response = JSON.parse(data);
                switch (response.log) {
                    case 'sessione scaduta':
                        this.setModalVisible(true);
                        sessionStorage.clear();
                        break;
                    default:
                        break;
                }
            });
        }else{ 
            
            this.props.history.push({
                pathname: '/'
            }); }
    }

    error() {
        Modal.error({
          title: 'Si è verificato un errore',
          content: 'Prova nuovamente e se l\'errore dovesse ripetersi, contattare il supporto tecnico',
        });
      }

    success() {
        Modal.success({
          content: 'La tua candidatura è stata ritirata',
          onOk: () => this.getCards()
        });
        
      }

    changeChecked = (checkedValues) =>{
        console.log(checkedValues.toString());
       this.setState({reasons:checkedValues.toString()});
    }

      uSure(id){
          
          //console.log("questo è l'id passato: "+id);
          Modal.confirm({
            content: <div>Sei sicuro di voler ritirare la candidatura? <br/>
                            Se si, per quale motivo?<br/><br/>
                            <Checkbox.Group options={plainOptions} onChange={this.changeChecked}/><br/>
                        {/*<Checkbox>Contrattempo o imprevisto</Checkbox><br/>
                        <Checkbox>Cambio di disponibilità</Checkbox><br/>
                        <Checkbox>Tariffa insufficiente</Checkbox><br/>
                        <Checkbox>Motivi personali</Checkbox><br/>*/}

                     </div>,
            cancelText: "Torna Indietro",
            okText:"Ritira Candidatura",
            onOk: () => {
                this.noMoreAvailable(id);
            }
          })
      }

    sendEmailCancelCandidate = (id) =>{

        let formData = new FormData();
        formData.append("type","cancel_C");
        formData.append("id",id);
        formData.append("reasons",this.state.reasons);

        let provider = new Provider();
        
        provider.sendInfoEmail(formData).then(data => {
            console.log(data);
        });
    }

    noMoreAvailable = (id) =>{

        let formData = new FormData();
        formData.append("id",sessionStorage.getItem('id'));
        formData.append("idCandidatura",id);
        formData.append("updateType","disponibilita");
        let provider = new Provider();
        
         provider.updateCandidatura(formData).then(data => {   
             let response = JSON.parse(data);
             //console.log(response);
             switch (response.log) {
                case 'ok':
                    this.success();
                    console.log("l'id è "+id)
                    this.sendEmailCancelCandidate(id);
                    break;
                case 'error':
                    this.error();
                    break;
                default:
                    break;
            }

        });
    }

    getCards = () => {

        let formData = new FormData();
        formData.append("organizzazione",sessionStorage.getItem('organizzazione'));
        formData.append("page","candidature");   
   
        let provider = new Provider();
        
         provider.getCards(formData).then(data => {     
            this.setState({cards: data});
        });
    }

    getClosedCards = () => {

        let formData = new FormData();
        formData.append("organizzazione",sessionStorage.getItem('organizzazione'));
        formData.append("page","candidatureClosed");   
   
        let provider = new Provider();
        
         provider.getCards(formData).then(data => {       
            this.setState({cards: data});
            //console.log(this.state.cards);            
        });
    }

    changeCards(value) {
        //console.log(`selected ${value}`);
        this.setState({status:value});
        if(value === "Active")
        this.getCards();
        else
        this.getClosedCards();
      }

    componentDidMount(){
        this.getCards();
    }

    render() {

        let cards;
        if(this.state.cards.length){
        cards = this.state.cards.map(item => (
            <LazyLoad key={item.id}>
                    <Card className="homeCardRedirect" title={item.title} 
                        hoverable
                        extra={this.state.status === "Closed" ? <p style={{color:"red"}}>Closed</p> : item.disponibilita === "non disponibile" ? <p style={{color:"red"}}>Candidatura ritirata</p> : item.valutazione === "in valutazione" ? <p style={{color:"orange"}}>{item.valutazione}</p> : item.valutazione === "approvata" ? <p style={{color:"rgb(0, 204, 0)"}}>{item.valutazione}</p> : <p style={{color:"red"}}>{item.valutazione}</p>}
                        style={{ /*width: status"100%"*/}}>
                        <p>Status : {this.state.status === "Active" ? <span style={{color:"#00cc00"}}>Active</span> : <span style={{color:"red"}}>Closed</span>}</p>
                        <p>Candidato : {item.nome} {item.cognome}</p>
                        <p>Durata : {item.start_date} / {item.end_date}</p>
                        <p>Sede : {item.location}</p>
                        <p>Dettagli : {item.details}</p>
                        {item.disponibilita === "disponibile" ? <button className="noMoreAvailable" onClick={()=>this.uSure(item.id)}>Annulla</button> : ''}
                    </Card>
            </LazyLoad>))
        }
        
        return (
            <div className="homeBody" >
                <SideMenu home="tab line other" ads="tab other line" candidature="tab active line" candidati="tab other line" nome={sessionStorage.getItem('nome')+" "+sessionStorage.getItem('cognome')}/>
                <div className="homeContentAll">
                   <h1 className="homeContenth1">Le mie Candidature</h1>
                   <div className="homeContentBody">
                    {cards || ""}
                    </div>
                    <Select style={{position:"absolute",right:"40px",top:"35px",fontSize:"20px"}} onChange={this.changeCards.bind(this)} defaultValue="Active">
                        <Option value="Active">Active</Option>
                        <Option value="Closed">Closed</Option>
                    </Select>
                </div>
                <Modal
                    title="Si è verificato un errore"
                    centered
                    maskClosable={false}
                    closable={false}
                    footer={<Link to="/login"><button id="okModalButton">Ok</button></Link>}
                    visible={this.state.visible}
                    >
                    <p>La sessione è scaduta. Ti preghiamo di accedere nuovamente per continuare ad utilizzare la piattaforma</p>
                    
                </Modal>
            </div>
        )
    }
}
