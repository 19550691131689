import { Button, Card, Checkbox, Modal, Select } from "antd";
import React, { Component } from "react";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import SideMenu from "./SideMenu.jsx";
import InsertCandidate from "./insertCandidate.jsx";
import Provider from "./providerFE.jsx";

const { Option } = Select;

const plainOptions = [
  "Contrattempo o imprevisto",
  "Cambio di disponibilità",
  "Tariffa insufficiente",
  "Motivi familiari/personali",
];

export default class Candidati extends Component {
  state = {
    isModalVisible: false,
  };

  showModal = () => {
    this.setState({ isModalVisible: true });
  };

  /* handleOk = () => {
    this.setState({ isModalVisible: false });
  }; */

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      cards: [],
      status: "Active",
      reasons: "Motivi personali",
    };
  }

  setModalVisible(visible) {
    this.setState({ visible });
  }

  UNSAFE_componentWillMount() {
    if (sessionStorage.getItem("nome")) {
      let provider = new Provider();
      let formData = new FormData();
      formData.append("token", sessionStorage.getItem("token"));
      formData.append("id", sessionStorage.getItem("id"));
      provider.updateToken(formData).then((data) => {
        let response = JSON.parse(data);
        switch (response.log) {
          case "sessione scaduta":
            this.setModalVisible(true);
            sessionStorage.clear();
            break;
          default:
            break;
        }
      });
    } else {
      this.props.history.push({
        pathname: "/",
      });
    }
  }

  error() {
    Modal.error({
      title: "Si è verificato un errore",
      content:
        "Prova nuovamente e se l'errore dovesse ripetersi, contattare il supporto tecnico",
    });
  }

  success() {
    Modal.success({
      content: "La tua candidatura è stata ritirata",
      onOk: () => this.getCards(),
    });
  }

  changeChecked = (checkedValues) => {
    console.log(checkedValues.toString());
    this.setState({ reasons: checkedValues.toString() });
  };

  uSure(id) {
    //console.log("questo è l'id passato: "+id);
    Modal.confirm({
      content: (
        <div>
          Sei sicuro di voler ritirare la candidatura? <br />
          Se si, per quale motivo?
          <br />
          <br />
          <Checkbox.Group
            options={plainOptions}
            onChange={this.changeChecked}
          />
          <br />
          {/*<Checkbox>Contrattempo o imprevisto</Checkbox><br/>
                        <Checkbox>Cambio di disponibilità</Checkbox><br/>
                        <Checkbox>Tariffa insufficiente</Checkbox><br/>
                        <Checkbox>Motivi personali</Checkbox><br/>*/}
        </div>
      ),
      cancelText: "Torna Indietro",
      okText: "Ritira Candidatura",
      onOk: () => {
        this.noMoreAvailable(id);
      },
    });
  }

  sendEmailCancelCandidate = (id) => {
    let formData = new FormData();
    formData.append("type", "cancel_C");
    formData.append("id", id);
    formData.append("reasons", this.state.reasons);

    let provider = new Provider();

    provider.sendInfoEmail(formData).then((data) => {
      console.log(data);
    });
  };

  noMoreAvailable = (id) => {
    let formData = new FormData();
    formData.append("id", sessionStorage.getItem("id"));
    formData.append("idCandidatura", id);
    formData.append("updateType", "disponibilita");
    let provider = new Provider();

    provider.updateCandidatura(formData).then((data) => {
      let response = JSON.parse(data);
      //console.log(response);
      switch (response.log) {
        case "ok":
          this.success();
          console.log("l'id è " + id);
          this.sendEmailCancelCandidate(id);
          break;
        case "error":
          this.error();
          break;
        default:
          break;
      }
    });
  };

  getCards = () => {
    let formData = new FormData();
    formData.append("organizzazione", sessionStorage.getItem("organizzazione"));
    formData.append("page", "candidati");

    let provider = new Provider();

    provider.getCards(formData).then((data) => {
      this.setState({ cards: data });
    });
  };

  getClosedCards = () => {
    let formData = new FormData();
    formData.append("id", sessionStorage.getItem("id"));
    formData.append("page", "candidatureClosed");

    let provider = new Provider();

    provider.getCards(formData).then((data) => {
      this.setState({ cards: data });
      //console.log(this.state.cards);
    });
  };

  changeCards(value) {
    //console.log(`selected ${value}`);
    this.setState({ status: value });
    if (value === "Active") this.getCards();
    else this.getClosedCards();
  }

  componentDidMount() {
    this.getCards();
  }

  render() {
    let cards;
    if (this.state.cards.length) {
      cards = this.state.cards.map((item) => (
        <LazyLoad key={item.id}>
          <Link
            className="homeCardRedirect"
            to={{ pathname: "/candidati/" + item.id, state: { item: item } }}
          >
            <Card
              className="homeCardRedirect"
              title={item.title}
              hoverable
              extra={<p style={{ color: "orange" }}>+ info</p>}
            >
              <p>
                Candidato : {item.nome} {item.cognome}
              </p>
              <p>Figura Professionale : {item.figura_professionale}</p>
              <p>Anni di Esperienza : {item.anni_esperienza}</p>
              <p>Status : {item.status}</p>
            </Card>
          </Link>
        </LazyLoad>
      ));
    }

    return (
      <div className="homeBody">
        <SideMenu
          home="tab line other"
          ads="tab other line"
          candidature="tab other line"
          candidati="tab active line"
          nome={
            sessionStorage.getItem("nome") +
            " " +
            sessionStorage.getItem("cognome")
          }
        />
        <div className="homeContentAll">
          <button
            id="addJobPositionButton"
            className="tab"
            style={{ margin: "20px" }}
            onClick={this.showModal}
          >
            <i className="fas fa-plus"></i>
            <p>Inserisci Candidato</p>
          </button>
          <h1 className="homeContenth1">I tuoi Candidati</h1>
          <div className="homeContentBody">{cards || ""}</div>
          <Select
            style={{
              position: "absolute",
              right: "40px",
              top: "35px",
              fontSize: "20px",
            }}
            onChange={this.changeCards.bind(this)}
            defaultValue="Active"
          >
            <Option value="Active">Active</Option>
            <Option value="Closed">Closed</Option>
          </Select>
        </div>
        <Modal
          title="Si è verificato un errore"
          centered
          maskClosable={false}
          closable={false}
          footer={
            <Link to="/login">
              <button id="okModalButton">Ok</button>
            </Link>
          }
          visible={this.state.visible}
        >
          <p>
            La sessione è scaduta. Ti preghiamo di accedere nuovamente per
            continuare ad utilizzare la piattaforma
          </p>
        </Modal>

        <Modal
          title="Resource NET"
          visible={this.state.isModalVisible}
          onCancel={this.handleCancel}
          width="80%"
          cancelText="Annulla"
          footer={[
            <Button key="cancel" onClick={this.handleCancel}>
              Annulla
            </Button>,
          ]}
        >
          <InsertCandidate />
        </Modal>
      </div>
    );
  }
}
