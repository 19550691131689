import React, { Component } from 'react'
import Provider from './providerFE.jsx'
import { Modal, Divider, Card, Select, Input, Upload, Button, DatePicker, AutoComplete } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
import AddJob from './addJob.jsx'
import locale from 'antd/es/date-picker/locale/it_IT';
import LazyLoad from "react-lazyload";
import './styles/info.css'
import SideMenu from './SideMenu'

const { Option } = Select;


export default class info extends Component {
    constructor(props){
        super(props)
        this.state = {showButtonText:"Mostra Job Position",
        allegato:'',
        Linkedin:'',
        Skype:'',
        figura:'',
        livello:'',
        disponibile_dal:"",
        disponibile_al:'',
        esperienza_1:'',
        competenza_1:'',
        esperienza_2:'',
        competenza_2:'',
        esperienza_3:'',
        competenza_3:'',
        esperienza_4:'',
        competenza_4:'',
        showJobP:false,
        visible: false,
        data:{visit:''},
        options:[]
    }
    }

    setModalVisible(visible) {
        this.setState({ visible });
      }

    updateValue =  (element,event) =>{
    
     this.setState({
        [element] : event.target.value
        });
        
    }

    updateCompetenza =  (value,type) =>{

         this.setState({
            [type] : value
        });
    }
    
    changeCandidateStatus(id,value){
        console.log("id candidatura "+id+" e value "+value);
        let formData = new FormData();
        formData.append("idCandidatura",id);
        formData.append("updateType",value);
        let provider = new Provider();
        
        provider.updateCandidatura(formData).then(data => {
            //console.log(data);
            let response = JSON.parse(data);
            if(response.log === "ok"){
                this.getAllfromToken(this.state.job_value);
                this.sendEmailChangeValue(id,value);
            }

        });
    }

    sendEmailChangeValue = (id,value) =>{

        let formData = new FormData();
        formData.append("type","change_V");
        formData.append("status",value);
        formData.append("id",id);


        let provider = new Provider();
        
        provider.sendInfoEmail(formData).then(data => {
            console.log(data);
        });
    }

    changeColor(e) {
        e.target.style.borderBottom = "1px solid #009dd6";
    }

    error(log) {
        Modal.error({
          title: 'Si è verificato un errore',
          content: log,
        });
      }

    success(log) {
        Modal.success({
          content: log,
          onOk: () => {this.props.history.push({
            pathname: '/candidature'
            });}
        });
      }

    infoCandidato(item) {
        Modal.info({
            title: <><strong>{item.nome_azienda}</strong><p style={{ fontSize: 14, margin: 0 }}>Email : {item.email_azienda}</p></>,
            width:"767px",
            maskClosable: true,
            content: 
            <div id="modalInfoContainer">
                <div style={{paddingTop:"20px"}}>
                    <p>Candidato : {item.nome + " " + item.cognome}</p>
                    <p>Data di Nascita : {item.data_di_nascita}</p>
                    <p>Figura Professionale : <br/>{item.figura_professionale} {item.livello}</p>
                    <p>Disponibilità : {item.disponibilita === "disponibile" ? <span style={{color:"rgb(0, 204, 0)"}}>Disponibile</span> : <span style={{color:"red"}}>Non disponibile</span>}</p>
                    <p>Disponibile dal : {item.disponibile_dal} fino al {item.disponibile_al}</p>
                    <p>Email : {item.email}</p>
                    <p>Indirizzo : {item.indirizzo}</p>
                    <p>Telefono : {item.telefono}</p>
                    <p>Nazionalità : {item.nazionalita}</p>
                    <p>Tariffa (oraia) : {item.tariffa_oraria}</p>
                    <p><a href={window.location.origin + '/lumi' + item.allegato} download target="_blank" rel="noopener noreferrer">Download CV</a></p>
                    <div> Status : {item.disponibilita === "disponibile" ?
                    <Select onChange={this.changeCandidateStatus.bind(this,item.id)} defaultValue={item.valutazione}>
                        <Option style={{color:"orange"}} value="in valutazione">In Valutazione</Option>
                        <Option style={{color:"rgb(0, 204, 0)"}} value="approvata">Approvata</Option>
                        <Option style={{color:"red"}} value="rigettata">Rigettata</Option>
                    </Select>: <span style={{color:"red"}}>Non disponibile</span>}
                    </div>
                </div>
                <div>
                    <p>Luogo di Nascita : {item.luogo_di_nascita}</p>
                    <p>Sesso : {item.sesso}</p>
                    <p>Città : {item.citta}</p>
                    <p>Provincia : {item.provincia} CAP : {item.cap}</p>
                    <p>Codice Fiscale : {item.codice_fiscale}</p>
                    <p>Settore : {item.settore}</p>
                    {item.linkedin ? <p>Linkedin : {item.linkedin}</p>: ''}
                    {item.skype ? <p>Skype : {item.skype}</p>: ''}
                    {item.competenza_1 ? <p style={{marginBottom: 0}}>Esperienze : <br/>{item.esperienza_1} di {item.competenza_1}</p>: ''}
                    {item.competenza_2 ? <p style={{marginBottom: 0}}>{item.esperienza_2} di {item.competenza_2}</p>: ''}
                    {item.competenza_3 ? <p style={{marginBottom: 0}}>{item.esperienza_3} di {item.competenza_3}</p>: ''}
                    {item.competenza_4 ? <p>{item.esperienza_4} di {item.competenza_4}</p>: ''}

                </div>
            </div>
        });
      }

    sendEmailNewCandidate = () =>{

        let formData = new FormData();
        formData.append("type","new_C");
        formData.append("data",JSON.stringify(this.state).toString());

        let provider = new Provider();
        
        provider.sendInfoEmail(formData).then(data => {
            console.log(data);
        });
    }

    insertCandidato = () =>{
        
        let formCompleted = this.verify();
        if(!formCompleted) return;

        let formData = new FormData();
        formData.append("id",sessionStorage.getItem('id')); 
        formData.append("data",JSON.stringify(this.state).toString()); 
        formData.append("file",this.state.allegato.file);   
  
        let provider = new Provider();
        
        provider.insertCandidatura(formData).then(data => {
            console.log(data);
            switch (data.type) {
                case 'error':
                    this.error(data.log);
                    break;
                case 'ok':
                    this.success(data.log);
                    this.sendEmailNewCandidate();
                    break;    
                default:
                    break;
            }
        });

    }

    verify = () =>{
        let form = document.getElementById('subForm');
        let error = document.getElementById('inputErrorCandidatura');
        let email = document.getElementById('inputRegistrazioneEmail');
        let dataNascita =  document.querySelector("#formCandidatura > div > div > input");


        let children = form.children;
        let fine = true;
        error.style.display = "none";

        Array.prototype.forEach.call(children, child => {
            if(child.value === "" || child.value == null){
                child.style.borderBottom = "1px solid red";
                error.style.display = "block";
                fine = false;
            }
          });

          if(dataNascita.value === "" || dataNascita.value == null){
            dataNascita.style.borderBottom = "1px solid red";
                error.style.display = "block";
                fine = false;
        }

        if(this.state.allegato === "" || this.state.allegato == null){
                error.style.display = "block";
                error.innerHTML = "Si è verificato un errore con il CV inserito";
                fine = false;
        }

          if(fine){
            if(!email.value.includes("@")){
                fine = false;
                email.style.borderBottom = "1px solid red";
                error.style.display = "block";
                error.innerHTML = "l'Email inserita non è corretta";
            }
            else{
                error.innerHTML ="controlla per cortesia di aver compilato correttamente tutti i campi";
            }
        }
          return fine;
    }

    componentWillMount(){
       
        if(sessionStorage.getItem('nome')){
            let provider = new Provider();
            let formData = new FormData();
            formData.append("token",sessionStorage.getItem('token')); 
            formData.append("id",sessionStorage.getItem('id')); 
            provider.updateToken(formData).then(data => {  
                let response = JSON.parse(data);
                switch (response.log) {
                    case 'sessione scaduta':
                        this.setModalVisible(true);
                        sessionStorage.clear();
                        break;
                    default:
                        const {token} = this.props.match.params;
                        this.setState({job_value:token});
                        this.getAllfromToken(token);
                        this.getCompetenze();
                        break;
                }
            });
        }else{
            this.props.history.push({
                pathname: '/'
            }); 
        }
    }

    componentDidMount(){
        document.querySelector("#root > div > div:nth-child(1) > div:nth-child(3) > div").style.width = "0px";
    }
    componentWillUnmount(){
        document.querySelector("#root > div > div:nth-child(1) > div:nth-child(3) > div").style.width = "36px";
    }

    getCompetenze(){
        let provider = new Provider();
           provider.getCompetenze().then(data =>{

            let options = [...this.state.options];
            data.forEach(elem => options.push({ value: elem }));
            this.setState({ options });
        });
    }

    getAllfromToken(token){

        let formData = new FormData();
        let provider = new Provider();
        formData.append("id",sessionStorage.getItem('id'));
        formData.append("token",token);  

           provider.getAllinfo(formData).then(data =>{

            this.setState({"data": data});
            //console.log(this.state)
        });

    }

    changeLivello(value) {
        this.setState({livello:value});
    }

    changeEsperienza(id,value) {
        let esperienza = `esperienza_${id}`;
        this.setState({[esperienza]:value});
    }

    saveDataNascita(){
        let data =  document.querySelector("#formCandidatura > div > div > input");
        this.setState({data_di_nascita:data.value});
    }

    saveDisponibilita(type,e){
        this.setState({[type]:e.target.value});
    }

    showJobP = () => {
        if(this.state.showJobP === false){
            this.setState({showButtonText:"Nascondi Job Position"});
            this.setState({showJobP:true});
            return
        }else{
            this.setState({showButtonText:"Mostra Job Position"});
            this.setState({showJobP:false});
            return
        }

    }

    render() {

        const props = {
            onChange: file => {
                this.setState({allegato: file})
            },
            beforeUpload: () =>{
                return false;
            }
        };

        let body;
        if(this.state.data.visit === true){//la job p non è stata fatta da me
            body = <div className="candidatureBody homeContentAll">
                    <h1>{this.state.data.title}</h1><br/>
                    <div className="candidatureBox">
                        <Divider orientation="left">Dettagli</Divider>
                        <p>{this.state.data.details}</p>
                    </div>
                    <div className="candidatureBox">
                        <Divider orientation="left">Status</Divider>
                        <p>{this.state.data.status}</p>
                    </div>
                    <div className="candidatureBox">
                        <Divider orientation="left">Risorse Necessarie</Divider>
                        <p>{this.state.data.posizioni_disponibili}</p>
                    </div>
                    <div className="candidatureBox">
                        <Divider orientation="left">Durata</Divider>
                        <p>Dal giorno : {this.state.data.start_date} al giorno : {this.state.data.end_date}</p>
                    </div>
                    <div className="candidatureBox">
                        <Divider orientation="left">Location</Divider>
                        <p>{this.state.data.location}</p>
                    </div>
                    <div className="candidatureBox">
                        <Divider orientation="left">Settore</Divider>
                        <p>{this.state.data.settore ? this.state.data.settore : "Non è stato specificato"}</p>
                    </div>
                    <div className="candidatureBox">
                        <Divider orientation="left">Orario</Divider>
                        <p>{this.state.data.orario ? this.state.data.orario : "Non è stato specificato un orario"}</p>
                    </div>
                    <div className="candidatureBox">
                        <Divider orientation="left">Tariffa</Divider>
                        <p>{this.state.data.tariffa ? this.state.data.tariffa+" Euro ( "+this.state.data.rate_type+" )" : "Da definire"}</p>
                    </div>
                    <div className="candidatureBox">
                        <Divider orientation="left">Skills Principali</Divider>
                        <p>{this.state.data.primary_skills}</p>
                    </div>

                    {this.state.data.secondary_skills ? <div className="candidatureBox"><Divider orientation="left">Skills Seondarie</Divider>
                    <p>{this.state.data.secondary_skills}</p></div> : ''}
                    {this.state.data.tertiary_skills ? <div className="candidatureBox"><Divider orientation="left">Skills Terziarie</Divider>
                    <p>{this.state.data.tertiary_skills}</p></div> : ''}
                    {this.state.data.nice_to_have ? <div className="candidatureBox"><Divider orientation="left">Skills NON Obbligatorie</Divider>
                    <p>{this.state.data.nice_to_have}</p></div> : ''}
                    {this.state.data.comments ? <div className="candidatureBox"><Divider orientation="left">Commenti Aggiuntivi</Divider>
                    <p>{this.state.data.comments}</p></div> : ''}
                    <h2 id="candidatiText">Candidati ora</h2>
                    <p style={{width:"100%",textAlign:"center",paddingLeft:0}}>( i campi con * sono obbligatori )</p>
                    <p style={{width:"100%",textAlign:"center",paddingLeft:0, color: 'orange'}}>(i campi qui sotto sono visualizzabili da te)</p>
                    <i className="fas fa-chevron-down" style={{width:"100%",textAlign:"center",marginBottom:"30px"}}></i>

                    <div id="formCandidatura">
                        <div id="subForm">
                        <input className="halfInputSignIn" onChange={this.updateValue.bind(this,"nome")} onFocus={this.changeColor.bind(this)} placeholder="* Nome" type="Text"/>
                        <input className="halfInputSignIn" onChange={this.updateValue.bind(this,"cognome")} onFocus={this.changeColor.bind(this)} placeholder="* Cognome" type="Text"/>
                        <input id="inputRegistrazioneEmail" className="halfInputSignIn" onChange={this.updateValue.bind(this,"email")} onFocus={this.changeColor.bind(this)} placeholder="* Email" type="email"/>
                        <input className="halfInputSignIn" onChange={this.updateValue.bind(this,"citta")} onFocus={this.changeColor.bind(this)} placeholder="* Città" type="Text"/>
                        <input className="halfInputSignIn" onChange={this.updateValue.bind(this,"provincia")} onFocus={this.changeColor.bind(this)} placeholder="* Provincia" type="Text"/>
                        <input className="halfInputSignIn" onChange={this.updateValue.bind(this,"indirizzo")} onFocus={this.changeColor.bind(this)} placeholder="* Indirizzo" type="Text"/>
                        <input className="halfInputSignIn" onChange={this.updateValue.bind(this,"cap")} onFocus={this.changeColor.bind(this)} placeholder="* CAP" type="number"/>
                        <input className="halfInputSignIn" onChange={this.updateValue.bind(this,"nazionalita")} onFocus={this.changeColor.bind(this)} placeholder="* Nazionalità" type="Text"/>
                        <input className="halfInputSignIn" onChange={this.updateValue.bind(this,"sesso")} onFocus={this.changeColor.bind(this)} placeholder="* Sesso" type="Text"/>
                        <input className="halfInputSignIn" onChange={this.updateValue.bind(this,"telefono")} onFocus={this.changeColor.bind(this)} placeholder="* Telefono" type="number"/>
                        <input className="halfInputSignIn" onChange={this.updateValue.bind(this,"tariffa_oraria")} onFocus={this.changeColor.bind(this)} placeholder="* Tariffa Oraria" type="email"/>
                        

                        <input className="halfInputSignIn" onChange={this.updateValue.bind(this,"luogo_di_nascita")} onFocus={this.changeColor.bind(this)} placeholder="* Luogo di Nascita" type="Text"/>
                        <input className="halfInputSignIn" onChange={this.updateValue.bind(this,"codice_fiscale")} onFocus={this.changeColor.bind(this)} placeholder="* Codice Fiscale" type="Text"/>
                        <input className="halfInputSignIn" onChange={this.updateValue.bind(this,"settore")} onFocus={this.changeColor.bind(this)} placeholder="* Settore di Competenza" type="Text"/>
                        </div>
                        
                        
                        <input className="halfInputSignIn" onChange={this.updateValue.bind(this,"Linkedin")} onFocus={this.changeColor.bind(this)} placeholder="Linkedin" type="Text"/>
                        <input className="halfInputSignIn" onChange={this.updateValue.bind(this,"Skype")} onFocus={this.changeColor.bind(this)} placeholder="Skype" type="Text"/>
                        <DatePicker onFocus={this.changeColor.bind(this)} onBlur={this.saveDataNascita.bind(this)} locale={locale} allowClear={true}  placeholder="* Data di Nascita" className="halfInputAddJob"/>
                        
                        <DatePicker onFocus={this.changeColor.bind(this)} onBlur={this.saveDisponibilita.bind(this,"disponibile_dal")} locale={locale} allowClear={true}  placeholder="Disponibile dal" className="halfInputAddJob"/>
                        <DatePicker onFocus={this.changeColor.bind(this)} onBlur={this.saveDisponibilita.bind(this,"disponibile_al")} locale={locale} allowClear={true}  placeholder="Disponibile fino al" className="halfInputAddJob"/>

                        <Input.Group className="halfInputAddJob" style={{float:"right"}} compact >
                        <Input style={{ width: '60%',border:'none',boxShadow:"none" }} type="text" onChange={this.updateValue.bind(this,"figura")} placeholder="Figura Professionale" />
                        <Select style={{width: "40%"}} onChange={this.changeLivello.bind(this)} placeholder="Livello" >
                            <Option value="Junior">Junior</Option>
                            <Option value="Middle">Middle</Option>
                            <Option value="Senior">Senior</Option>
                        </Select>
                        </Input.Group>
                        <Input.Group className="halfInputAddJob" style={{float:"left"}} compact >
                        <AutoComplete
                            options={this.state.options}
                            onChange={(inputValue) => this.updateCompetenza(inputValue,"competenza_1")}
                            placeholder="Competenza Uno"
                            style={{fontSize:"16px", fontWeight:500, color: "black",width: '65%',border:'none',boxShadow:"none"}}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                        <Select style={{width: "35%"}} onChange={this.changeEsperienza.bind(this,1)} placeholder="Esperienza" >
                            <Option value="0.5 Anni">0.5 Anni</Option>
                            <Option value="1 Anno">1 Anno</Option>
                            <Option value="2 Anni">2 Anni</Option>
                            <Option value="3 Anni">3 Anni</Option>
                            <Option value="4 Anni">4 Anni</Option>
                            <Option value="5 Anni">5 Anni</Option>
                            <Option value="6 Anni">6 Anni</Option>
                            <Option value="7 Anni">7 Anni</Option>
                            <Option value="8 Anni">8 Anni</Option>
                            <Option value="9 Anni">9 Anni</Option>
                            <Option value="10 Anni">10 Anni</Option>
                            <Option value="Più di 10 Anni">Più di 10 Anni</Option>
                        </Select>
                        </Input.Group>
                        <Input.Group className="halfInputAddJob" style={{float:"right"}} compact >
                        <AutoComplete
                            options={this.state.options}
                            onChange={(inputValue) => this.updateCompetenza(inputValue,"competenza_2")}
                            placeholder="Competenza Due"
                            style={{fontSize:"16px", fontWeight:500, color: "black",width: '65%',border:'none',boxShadow:"none"}}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />                        
                        <Select style={{width: "35%"}} onChange={this.changeEsperienza.bind(this,2)} placeholder="Esperienza" >
                            <Option value="0.5 Anni">0.5 Anni</Option>
                            <Option value="1 Anno">1 Anno</Option>
                            <Option value="2 Anni">2 Anni</Option>
                            <Option value="3 Anni">3 Anni</Option>
                            <Option value="4 Anni">4 Anni</Option>
                            <Option value="5 Anni">5 Anni</Option>
                            <Option value="6 Anni">6 Anni</Option>
                            <Option value="7 Anni">7 Anni</Option>
                            <Option value="8 Anni">8 Anni</Option>
                            <Option value="9 Anni">9 Anni</Option>
                            <Option value="10 Anni">10 Anni</Option>
                            <Option value="Più di 10 Anni">Più di 10 Anni</Option>
                        </Select>
                        </Input.Group>
                        <Input.Group className="halfInputAddJob" style={{float:"left"}} compact >
                        <AutoComplete
                            options={this.state.options}
                            onChange={(inputValue) => this.updateCompetenza(inputValue,"competenza_3")}
                            placeholder="Competenza Tre"
                            style={{fontSize:"16px", fontWeight:500, color: "black",width: '65%',border:'none',boxShadow:"none"}}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                        <Select style={{width: "35%"}} onChange={this.changeEsperienza.bind(this,3)} placeholder="Esperienza" >
                            <Option value="0.5 Anni">0.5 Anni</Option>
                            <Option value="1 Anno">1 Anno</Option>
                            <Option value="2 Anni">2 Anni</Option>
                            <Option value="3 Anni">3 Anni</Option>
                            <Option value="4 Anni">4 Anni</Option>
                            <Option value="5 Anni">5 Anni</Option>
                            <Option value="6 Anni">6 Anni</Option>
                            <Option value="7 Anni">7 Anni</Option>
                            <Option value="8 Anni">8 Anni</Option>
                            <Option value="9 Anni">9 Anni</Option>
                            <Option value="10 Anni">10 Anni</Option>
                            <Option value="Più di 10 Anni">Più di 10 Anni</Option>
                        </Select>
                        </Input.Group>
                        <Input.Group className="halfInputAddJob" style={{float:"right"}} compact >
                        <AutoComplete
                            options={this.state.options}
                            onChange={(inputValue) => this.updateCompetenza(inputValue,"competenza_4")}
                            placeholder="Competenza Quattro"
                            style={{fontSize:"16px", fontWeight:500, color: "black",width: '65%',border:'none',boxShadow:"none"}}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                        <Select style={{width: "35%"}} onChange={this.changeEsperienza.bind(this,4)} placeholder="Esperienza" >
                            <Option value="0.5 Anni">0.5 Anni</Option>
                            <Option value="1 Anno">1 Anno</Option>
                            <Option value="2 Anni">2 Anni</Option>
                            <Option value="3 Anni">3 Anni</Option>
                            <Option value="4 Anni">4 Anni</Option>
                            <Option value="5 Anni">5 Anni</Option>
                            <Option value="6 Anni">6 Anni</Option>
                            <Option value="7 Anni">7 Anni</Option>
                            <Option value="8 Anni">8 Anni</Option>
                            <Option value="9 Anni">9 Anni</Option>
                            <Option value="10 Anni">10 Anni</Option>
                            <Option value="Più di 10 Anni">Più di 10 Anni</Option>
                        </Select>
                        </Input.Group>
                        <Upload id="inputRegistrazioneAllegato" {...props} accept="image/*,.pdf,.doc" className="fullInputSignIn">
                            <Button>
                                <UploadOutlined /> Inserisci il CV ( file singolo )
                            </Button>
                        </Upload>

                    </div>
                    <p id="inputErrorCandidatura" style={{display:"none"}}>controlla per cortesia di aver compilato correttamente tutti i campi</p>
                    <button id="submitCandidatura" onClick={this.insertCandidato}>Invia</button>

                    </div>  

}else if(this.state.data.visit === false){//la job p è mia
    body = <div className="homeContentAll" style={{textAlign:"center",minHeight:"100vh"}}>
            <button id="showJobPosition" onClick={() =>this.showJobP() }>{this.state.showButtonText}</button>
            {this.state.showJobP === true ? <AddJob defaultValues={this.state.data} modify={true}/> : ''}
            <hr style={{border:"none",borderBottom:"1px solid #dedede",width:"60%",margin:"50px auto"}}/>
            <h2 id="candidatiText">Lista dei Candidati</h2>
            <i className="fas fa-chevron-down" style={{width:"100%",textAlign:"center"}}></i>
            <div className="homeContentBody">
            {this.state.data.candidature ? this.state.data.candidature.map(item => (
                        <LazyLoad key={item.id}>

                                <Card style={{textAlign:"left"}} className="homeCardRedirect" 
                                    title={<>
                                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", width:"100%"}}>
                                            {item.nome_azienda}
                                            <span style={{fontSize: 14, margin: 0, fontWeight: 500}}>{item.disponibilita === "non disponibile" ? <span style={{color:"red"}}>Non disponibile</span> : item.valutazione === "in valutazione" ? <span style={{color:"orange"}}>In valutazione</span> : item.valutazione === "approvata" ? <span style={{color:"rgb(0, 204, 0)"}}>Approvata</span> : <span style={{color:"red"}}>{item.valutazione}</span>}</span>
                                        </div>
                                        <p style={{ fontSize: 14, margin: 0, fontWeight: 500}}>Email : {item.email_azienda}</p></>} 
                                    hoverable onClick={() => this.infoCandidato(item)} 
                                >
                                    <p>Candidato : {item.nome + " " + item.cognome}</p>
                                    <p>Disponibilità : {item.disponibilita === "disponibile" ? <span style={{color:"rgb(0, 204, 0)"}}>Disponibile</span> : <span style={{color:"red"}}>Non disponibile</span>}</p>
                                    <p>Città : {item.citta}</p>
                                    {item.competenza_1 ? <p style={{marginBottom: 0}}>Esperienze : <br/>{item.esperienza_1} di {item.competenza_1}</p>: ''}
                                    {item.competenza_2 ? <p style={{marginBottom: 0}}>{item.esperienza_2} di {item.competenza_2}</p>: ''}
                                    {item.competenza_3 ? <p style={{marginBottom: 0}}>{item.esperienza_3} di {item.competenza_3}</p>: ''}
                                    {item.competenza_4 ? <p style={{marginBottom: 0}}>{item.esperienza_4} di {item.competenza_4}</p>: ''}
                                    <p style={{marginTop: "1rem"}} >Tariffa (oraia) : {item.tariffa_oraria}</p>
                                </Card>
                            
            </LazyLoad>)) : <p style={{fontSize:"20px",width:"100%"}}>Non ci sono ancora candidature</p>}
            </div>
           </div>
    
        }else body = '';

        return (
            <div className="homeBody">
                <SideMenu isMine={!this.state.data.visit} job={this.state.data.title} home="tab line other" ads="tab other line" candidature="tab other line" candidati="tab other line" nome={sessionStorage.getItem('nome')+" "+sessionStorage.getItem('cognome')}/>
                {body || ''}
                <Modal
                    title="Si è verificato un errore"
                    centered
                    maskClosable={false}
                    closable={false}
                    footer={<Link to="/login"><button id="okModalButton">Ok</button></Link>}
                    visible={this.state.visible}
                    >
                    <p>La sessione è scaduta. Ti preghiamo di accedere nuovamente per continuare ad utilizzare la piattaforma</p>
                    
                </Modal>
            </div>
        )
    }
}
