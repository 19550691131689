import React from 'react';
import './App.css';
import Login from './components/login.jsx';
import Registrazione from './components/registrazione.jsx';
import Pending from './components/pending.jsx';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Index from './components/index.jsx';
import Confirm from './components/confirm.jsx';
//import AddJobPosition from './components/addJob.jsx';
import Home from './components/home.jsx';
import ADS from './components/ads.jsx';
import Candidature from './components/candidature.jsx';
import Candidati from './components/candidati.jsx';
import Candidato from './components/candidato.jsx';
import Burger from './components/Burger.jsx'
import Info from './components/info.jsx'

function App() {
  return (
    <Router>
    <div className="App">
      <Burger/>
      <Switch>
        <Route path="/" exact component={Index} />
        <Route path="/login" component={Login} />
        <Route path="/confirm/:token" component={Confirm} />
        <Route path="/registrazione" component={Registrazione} />
        <Route path="/pending" component={Pending} />
        {/*<Route path="/addjob" component={AddJobPosition} />*/}
        <Route path="/home" component={Home} />
        <Route path="/myads" component={ADS} />
        <Route path="/candidature" component={Candidature} />
        <Route path="/candidati" exact component={Candidati} />
        <Route path="/candidati/:id" component={Candidato} />
        <Route path="/info/:token" component={Info} />

      </Switch>
    </div>
    </Router>
  );
}

export default App;
